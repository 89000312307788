import React from "react";
import './App.css';
import Home from './pages/Home';
import LeadGeneration from './pages/LeadGeneration';
import Audit from './pages/Audit';
import Consulting from './pages/Consulting';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "./tailwind.css";
import "./hamburgers.css"
import Navbar from "./components/Navbar";


function App() {

  return (
    <>
    <BrowserRouter>
    
 <Navbar></Navbar>

      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/leadgeneration" element={<LeadGeneration />} />
      <Route path="/audit" element={<Audit />} />
      <Route path="/consulting" element={<Consulting />} />
      </Routes>

      
    </BrowserRouter>
    </>
  );
}

export default App;