import React from "react";
import lead_b1 from "../images/lead-b1.svg";
import b1m from "../images/b1m.svg";
import b1l from "../images/b1l.svg";
import line_form from "../images/line.svg";
import b2 from "../images/b2.svg";
import arrow_right from "../images/dotted-arr-right.png";
import arrow_left from "../images/dotted-arr-left.png";
import Footer from "../components/Footer";
import SocialSlider from "../components/carousel/SocialSlider";
import Company from "../components/carousel/Company";





const LeadGeneration = () => {
    return(
        <>
                    <main>
            {/* section 001 */}
            <section className="pt-md-5 pt-5 mt-5">
               
<div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto md:min-h-[700px] sm:min-h-[410px] min-h-[210px]  flex md:gap-10 items-center xl:relative sm:mb-32 mb-60">
   
    <div class="md:w-1/2 w-3/5 relative">
       
        <h1 class=" lg:text-[44px] sm:text-4xl text-xl font-bold text-white lg:leading-[57px!important] xl:w-3/4">Hyper-targeted lead generation</h1>
       
        <p class=" lg:text-xl sm:text-base text-[10px] text-white lg:mt-4 sm:mt-3 mt-2 md:mb-16 mb-5 font-mulish">Instantly grow profits by leveraging state of the <br/> art tools that provide you with quality leads.</p>
       
        <form action="#">
           
            <input class="w-full md:h-14 h-7 px-10 p-3 text-slate-500 rounded-full border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px]" name="search" type="text" id="search" placeholder="What service do you provide?" />
           
            <button type="submit" class="block md:mt-12 mt-5 outline-none lg:text-lg text-xs text-white font-bold rounded-full bg-[#1B47B8] sm:px-12 px-7 py-2 sm:py-3.5 sm:mr-0 sm:ml-auto">Get Started</button>
        </form>

        <div class="block absolute sm:-left-12 -left-3 -bottom-24">
            <div class="inline-flex items-center transform -rotate-90 w-auto">
               
                <div class="mr-1 mt-1 lg:w-16 sm:w-10 w-6 h-[1px] bg-blue-700"></div>
               
                <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
               
                <p class="lg:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish mb-0">Scroll down</p>
            </div>
        </div>
    </div>
   
    <div>
       
        <img height="100%" width="100%" class=" select-none block md:hidden absolute top-1.5  -right-0.5 w-auto h-auto -z-50" src={b1m} alt="//" />
       
        <img height="100%" width="100%" class=" select-none hidden md:block xl:hidden absolute -top-2.5 right-0 w-auto h-auto -z-50" src={b1l} alt="//" />
       
        <img height="100%" width="100%" class=" select-none hidden xl:block absolute top-[-283px] right-[-467px] w-auto h-auto -z-50" src={lead_b1} alt="//" />
    </div>
    {/* social */}
    <div class="sm:space-y-7 space-y-3 xl:space-y-3 block absolute xl:right-0 md:right-20 right-10 xl:-bottom-64 bottom-auto z-20 top-[440px] md:top-[845px] lg:top-[945px] xl:top-auto">
    {/* insta */}
        <a class=" text-white block p-2 icon-hov" aria-label="instagram" href="https://www.instagram.com/">
            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
            </svg>
        </a>
        {/* fb */}
        <a class=" text-white block p-2 icon-hov" aria-label="facebook" href="https://www.facebook.com/">
            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
            </svg>
        </a>
        {/* twi */}
        <a class=" text-white block p-2 icon-hov" aria-label="Twitter" href="https://twitter.com/">
            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
            </svg>
        </a>
    </div>
</div>
</section>
            {/* section 2 */}
            <section class=" relative">
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto circle-line">
                    
                    <div class=" grid md:grid-cols-2 grid-cols-1 items-center md:gap-20 gap-16 sm:pb-5">
                        
                        <div class="pt-28 pb-20 md:space-y-9 space-y-7 relative">
                            
                            <div class=" flex items-start text-blue-50">
                                
                                <div class="lg:h-[69px] h-12 w-12 lg:w-[69px] rounded-full bg-blue-50 lg:mr-9 mr-6 line-circle line-circle-sm"></div>
                                
                                <div>
                                    
                                    <p class=" font-mulish sm:text-xl text-xs lg:py-3.5 py-3 pb-3.5 lg:mt-2.5">Get Quality Leads</p>
                                    
                                    <ul class=" list-disc sm:text-base text-xs space-y-0.5 opacity-60"> 
                                        
                                        <li>Pre-qualified local &amp; nationwide leads</li>
                                        
                                        <li>Hyper-targeted segmentation</li>
                                        
                                        <li>Review leads before you commit</li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class=" flex items-start text-blue-50 mt-0">
                                
                                <div class="lg:h-11 h-8 w-8 lg:w-11 rounded-full bg-blue-50 lg:mr-12 md:mr-9 mr-6 ml-3 line-circle line-circle-sm1"></div>
                                
                                <div>
                                    
                                    <p class=" font-mulish sm:text-xl text-xs lg:py-2 pb-3.5 pt-2.5 sm:pt-0">Choose How You Pay</p>
                                    
                                    <ul class=" list-disc sm:text-base text-xs space-y-0.5 opacity-60"> 
                                        
                                        <li>Pay per lead</li>
                                        
                                        <li>Subscribe to a set number of leads per month</li>
                                        
                                        <li>Scale your leads as you grow</li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class=" flex items-start text-blue-50 mt-0 ">
                                
                                <div class="lg:h-11 h-8 w-8 lg:w-11 rounded-full bg-blue-50 lg:mr-12 md:mr-9 mr-6 ml-3 line-circle line-circle-sm2"></div>
                                
                                <div>
                                    
                                    <p class=" font-mulish sm:text-xl text-xs lg:py-2 pb-3.5 pt-2.5 sm:pt-0">Lead Integrity</p>
                                    
                                    <ul class=" list-disc sm:text-base text-xs space-y-0.5 opacity-60"> 
                                        
                                        <li>Ethical &amp; compliant lead generation</li>
                                        
                                        <li>Advanced targeting technology</li>
                                        
                                        <li>Add or change target criteria at any time</li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class=" blue-line w-[1px]  h-[240px] sm:h-[170px] bg-black absolute top-[123px] lg:left-[34px] left-[27px] -z-10"></div>
                        </div>
                        
                        <div class=" w-full">
                            
                            <div class="md:w-1/2 sm:w-1/3 w-1/4 h-[33px] sm:h-[38px] lg:h-[54px] rounded-full bg-[#C4C4C4]"></div>
                            
                            <div class="md:mt-24 mt-10 w-[85%] h-2.5 rounded-full bg-[#C4C4C4]"></div>
                            
                            <div class="md:mt-5 mt-3 w-[90%] h-2.5 rounded-full bg-[#C4C4C4]"></div>
                            
                            <div class="md:mt-6 mt-4 w-[95%] h-2.5 rounded-full bg-[#C4C4C4]"></div>
                            
                            <div class="md:mt-7 mt-5 w-full h-2.5 rounded-full bg-[#C4C4C4]"></div>
                            
                            <ul class=" list-disc list-inside md:mt-7 mt-5">
                                
                                <li class=" opacity-60 text-blue-50 sm:text-xs text-base">Get leads sent to you in real time</li>
                            </ul>
                        </div>
                        
                        <div class=" blue-area lg:rounded-tr-[246px] sm:lg:rounded-tr-[180px] rounded-tr-[100px] bg-[#1B47B8] absolute left-0 md:right-1/2 right-0 md:h-full -z-50 top-0 bottom-1/3" > </div>
                    </div>
                </div>
            </section>
            {/* section 3 */}
            <section class=" relative">
                
                <div class="xl:max-w-[1138px] lg:max-w-[1000px] mx-auto relative xl:py-56 md:py-44 py-24 my-8 sm:py-0">
                    
                    <div class="w-4/5 md:w-3/4 lg:w-4/5 xl:w-3/4 text-center mx-auto text-white mb-4">
                        
                        <h1 class=" lg:text-4xl sm:text-3xl text-lg mb-3 sm:mb-4 lg:mb-6">How do we find your leads?</h1>                    
                        
                        <p class=" text-[10px] sm:text-sm lg:text-lg">We leverage the technology of the third-party partners below. We tailor each lead generation strategy to deliver leads
                        that are actively searching for your products or services.</p>
                    </div>


                    {/* slider carousel */}

                    <SocialSlider/>

                </div>
                
                <div>
                    
                    <img height="100%" width="100%" class="absolute top-0 sm:right-0 -right-24 w-1/2 sm:w-1/4 lg:w-[30%] 2xl:w-auto h-auto object-cover -z-10" src={arrow_right} alt="//" />
                    
                    <img height="100%" width="100%" class="hidden md:block absolute bottom-0 left-0 w-1/2 sm:w-1/4 lg:w-[30%] 2xl:w-auto h-auto object-cover -z-10" src={arrow_left} alt="//" />
                </div>
            </section>
            {/* section 004 */}
            <section>
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto relative">
                    {/* heading */}
                    <h1 class="flex items-center justify-center lg:text-[44px] sm:text-[33px] text-2xl text-white font-bold text-center lg:mb-28 mb-20">
                        <p class="lg:w-24 lg:h-24 sm:w-20 sm:h-20 w-14 h-14 rounded-full bg-[#1B47B8] flex items-center justify-center mr-2"> Our</p>
                        <p>Features</p>
                    </h1>
                    
                    <div class=" grid sm:grid-cols-3 grid-cols-2 sm:gap-28 gap-4 gap-y-10 sm:gap-y-28 pb-20">
                        
                        <div class="text-white text-center">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">No Random Lists</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">Hyper-targeted lead generation.</p>
                        </div>
                        
                        <div class="text-white text-center">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">Custom Lead Segments</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">If you know your ideal client, we can find you more.</p>
                        </div>
                        
                        <div class="text-white text-center">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">Flexible</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">Designate city, country, or region.</p>
                        </div>
                        
                        <div class="text-white text-center">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">Location-based</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">Designate city, country, or region.</p>
                        </div>
                        
                        <div class="text-white text-center hidden sm:block">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">No Contracts</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">Cancel our services at any time.</p>
                        </div>
                        
                        <div class="text-white text-center hidden sm:block">
                            
                            <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl mx-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z" fill="#F5F8FF"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z" fill="#F5F8FF"></path>
                                </svg>
                            </div>
                            
                            <p class="text-xs lg:text-base lg:mt-12 sm:mt-10 mt-8 mb-1.5">Real-time</p>
                            
                            <p class="text-[9px] sm:text-[10px] lg:text-sm opacity-90">Receive qualified leads today!</p>
                        </div>
                    </div>
                    {/* scroll down */}
                    <div class="block absolute -top-16 sm:-right-16 right-0">
                        <div class="inline-flex items-center transform -rotate-90 w-auto">
                            
                            <div class="mr-1 mt-1 lg:w-16 sm:w-10 w-6 h-[1px] bg-blue-700"></div>
                            
                            <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
                            
                            <p class="lg:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish mb-0">Scroll down</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* section 5 */}
            <section class=" bg-[#1B47B8]">
              
              <div class="xl:max-w-[1138px] lg:max-w-[1000px] mx-auto relative py-10 text-white">
                  <div className="py-0 my-0">
                    <Company/>
                  </div>
              </div>
          </section>
            {/* section 6 */}
            <section>
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto md:py-28 py-10 flex items-center justify-center">
                    
                    <div>
                        <img height="100%" width="100%" class="w-auto h-auto" src={b2} alt="//" />
                    </div>
                </div>
            </section>
            {/* section 6 */}
            <section>
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto grid md:grid-cols-5 grid-cols-1 pb-20 pt-10 items-center lg:gap-32 md:gap-10">
                    
                    <form class="md:col-span-3 space-y-7" action="#">
                        
                        <p class="text-xl sm:text-[28px] lg:text-4xl text-white">Send us a message</p>
                        
                        <div class=" grid grid-cols-2 gap-5">
                            
                            <input class="w-full md:h-14 h-7 px-6 p-3 text-slate-500 rounded-full border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px]" name="First-name" type="text" id="First-name" placeholder="First name" required="" />
                            
                            <input class="w-full md:h-14 h-7 px-6 p-3 text-slate-500 rounded-full border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px]" name="Last-name" type="text" id="Last-name" placeholder="Last name" required="" />
                        </div>
                        
                        <div class=" grid grid-cols-2 gap-5">
                            
                            <input class="w-full md:h-14 h-7 px-6 p-3 text-slate-500 rounded-full border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px]" name="Phone" type="text" id="Phone" placeholder="Phone number (optional)" />
                            
                            <input class="w-full md:h-14 h-7 px-6 p-3 text-slate-500 rounded-full border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px]" name="Website" type="text" id="Website" placeholder="Website (optional)" />
                        </div>
                        
                        <textarea class="w-full md:h-64 h-48 px-6 p-3 text-slate-500 rounded-2xl border border-[#1B47B8] bg-transparent outline-none sm:text-sm text-[8px] resize-none" name="message" type="text" id="message" placeholder="What service do you provide?" required=""></textarea>
                        
                        <button type="submit" class="block md:mt-12 mt-5 outline-none lg:text-lg sm:text-xs text-[10px] text-white font-bold rounded-full bg-[#1B47B8] lg:w-64 sm:w-44 w-36 h-7 sm:h-9 lg:h-12 mr-0 ml-auto">Email</button>
                    </form>
                    
                    <div class="md:col-span-2 xl:space-y-[103px] space-y-20 relative hidden md:block">
                        
                        <div class=" flex items-center">
                            
                            <div class="h-7 w-7 xl:w-[38px] xl:h-[38px] rounded-full bg-[#7095F2] text-white flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-4 h-3 w-3 sm:w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            
                            <p class=" text-white text-sm sm:text-xl ml-6 font-mulish">Real-Time Leads</p>
                        </div>
                        
                        <div class=" flex items-center">
                            
                            <div class="h-7 w-7 xl:w-[38px] xl:h-[38px] rounded-full bg-[#7095F2] text-white flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-4 h-3 w-3 sm:w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            
                            <p class=" text-white text-sm sm:text-xl ml-6 font-mulish">Choose Your Geo</p>
                        </div>
                        
                        <div class=" flex items-center">
                            
                            <div class="h-7 w-7 xl:w-[38px] xl:h-[38px] rounded-full bg-[#7095F2] text-white flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-4 h-3 w-3 sm:w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            
                            <p class=" text-white text-sm sm:text-xl ml-6 font-mulish">No Fixed Commitments</p>
                        </div>
                        
                        <div class=" flex items-center">
                            
                            <div class="h-7 w-7 xl:w-[38px] xl:h-[38px] rounded-full bg-[#7095F2] text-white flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-4 h-3 w-3 sm:w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            
                            <p class=" text-white text-sm sm:text-xl ml-6 font-mulish whitespace-nowrap text-ellipsis  overflow-hidden">Start Getting Leads Today</p>
                        </div>
                        
                        <div class="absolute top-3.5 -left-3.5 mt-[0px!important] -z-10">
                            
                            <img height="100%" width="100%" class="h-auto w-auto md:max-h-[311px] xl:max-h-max" src={line_form} alt="//" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer/>
        </>
    );
}
export default LeadGeneration;