import React, {useState} from "react";
import f_logo from "../images/footer-logo.png"
import us from "../images/us-flag.png"
import ca from "../images/ca-flag.png"
import uk from '../images/gb-flag.png'

const Footer = () => {
    const [isActive, setActive] = useState("false");
    const [isActive1, setActive1] = useState("false");
    const [isActive2, setActive2] = useState("false");

    const handleToggle = () => {
      setActive(!isActive);
    };
    const handleToggle1 = () => {
        setActive1(!isActive1);
      };
      const handleToggle2 = () => {
        setActive2(!isActive2);
      };

var year = new Date().getFullYear();
     
    return(
        <>
           <footer class="lg:bg-[#282828] ">
            
            <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-5 mx-auto lg:pt-24 pt-10 footer-sm-padding">
                {/* footer content */}
                <div class=" grid lg:grid-cols-4 grid-cols-2 gap-10 ">
                    
                    <div>
                        {/* logo */}
                        <a href="/" class="flex items-end font-josefinsans font-bold lg:text-2xl sm:text-lg text-sm text-white space-x-3 xl:leading-[2rem!important] leading-[1rem!important] whitespace-nowrap mb-10">
                            
                            <img height="100%" width="100%" class=" w-7 h-7 xl:w-10 xl:h-10 object-cover " src={f_logo} alt="//" />
                            
                            <span>Rekindle Data</span>
                        </a>
                        {/* mobile dropdown */}
                        <div class=" space-y-4 block lg:hidden ">
                            
                            <div x-data="{selected:null}"  class="space-y-2 mt-md-0 mt-5">
                                
                                <button type="button" onClick={handleToggle}  class=" sm:text-sm text-[10px] font-bold text-blue-50 pb-2 flex items-center cursor-pointer outline-none">
                                    <span>For Customers</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="transform h-4 w-4 ml-2 duration-700" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                
                                <ul x-ref="container1" className= {`space-y-2 relative overflow-hidden transition-all duration-700 ${isActive ? "d-none" : "d-block"}`} >
                                    
                                    <li class="sm:text-base text-xs font-mulish text-blue-50">
                                        <a class="hover:underline" href="/">Find a Professional</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-blue-50">
                                        <a class="hover:underline" href="/">How it Works</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-blue-50">
                                        <a class="hover:underline" href="/">Login</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div x-data="{selected:null}" class="space-y-2  mt-md-3 mt-1">
                                
                                <button  type="button" onClick={handleToggle1}  class="sm:text-sm text-[10px] font-bold text-blue-50 pb-2 flex items-center cursor-pointer outline-none">
                                    <span>For Professional</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="transform h-4 w-4 ml-2 duration-700" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                
                                <ul x-ref="container1" className={`space-y-2 relative overflow-hidden transition-all duration-700 ${isActive1 ? "d-none" : "d-block"}`} >
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">How it works</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Pricing</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Join as a Professional</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Help Centre</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Mobile App</a>
                                    </li>
                                </ul>
                            </div>
                            
                            <div x-data="{selected:null}" class="space-y-2 mt-md-3 mt-1">
                                
                                <button type="button" onClick={handleToggle2} class="sm:text-sm text-[10px] font-bold text-blue-50 pb-2 flex items-center cursor-pointer outline-none">
                                    <span>About</span>
                                    <svg  xmlns="http://www.w3.org/2000/svg" class="transform h-4 w-4 ml-2 duration-700" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                
                                <ul x-ref="container1" className={`space-y-2 relative overflow-hidden transition-all duration-700 ${isActive2 ? "d-none" : "d-block"}`}>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">About Rekindle Data</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Team</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Careers</a>
                                    </li>
                                    
                                    <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                        <a class="hover:underline" href="/">Blog</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class=" col-span-2 hidden lg:grid grid-cols-3 gap-5">
                        
                        <ul class="space-y-2">
                            
                            <li class="sm:text-sm text-[10px] font-bold text-blue-50 pb-2">For Customers</li>
                            
                            <li class="sm:text-base text-xs font-mulish text-blue-50">
                                <a class="hover:underline" href="/">Find a Professional</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-blue-50">
                                <a class="hover:underline" href="/">How it Works</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-blue-50">
                                <a class="hover:underline" href="/">Login</a>
                            </li>
                        </ul>
                        
                        <ul class="space-y-2 ">
                            
                            <li class="sm:text-sm text-[10px] font-bold text-blue-50 pb-2">For Professional</li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">How it works</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Pricing</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Join as a Professional</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Help Centre</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Mobile App</a>
                            </li>
                        </ul>
                        
                        <ul class="space-y-2">
                            
                            <li class="sm:text-sm text-[10px] font-bold text-blue-50 pb-2">About</li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">About Rekindle Data</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Team</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Careers</a>
                            </li>
                            
                            <li class="sm:text-base text-xs font-mulish text-[#7C7C7C]">
                                <a class="hover:underline" href="/">Blog</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div class=" text-white flex flex-col items-end">
                        
                        <div class=" text-right text-white">
                            
                            <p class="text-xs lg:text-[9px] font-mulish leading-tight">hello@rekindledata.com</p>
                            
                            <p class="lg:text-base text-sm leading-tight">(604) 222-2221</p>
                            
                            <p class="text-xs lg:text-[9px] font-mulish text-[#7C7C7C] leading-tight">(open 24 hours 7 days a
                                week)</p>
                        </div>
                        

                        
                        <div x-data="{modal:false}" class=" mt-3 w-full sm:w-auto">
                        <details class="custom-select">
                        <summary class="radios">
                            <input type="radio" name="item" id="default" title="Select Country..." checked />
                            <input type="radio" name="item" id="item1" title="United Kingdom" />
                            <input type="radio" name="item" id="item2" title="United States" />
                            <input type="radio" name="item" id="item3" title="Canada" />

                        </summary>
                        <ul class="list option-list">
                            <li>
                            <label className="d-flex label-text" for="item1">
                            <img height="100%" width="100%" class="w-4 h-4 object-contain mr-2" src={uk} alt="//" />       
                                <span>United Kingdom</span>
                            </label>
                            </li>
                            <li>
                            <label className="d-flex label-text" for="item2">
                            <img height="100%" width="100%" class="w-4 h-4 object-contain mr-2" src={us} alt="//" />       
                                <span>United States</span>
                            </label>
                            </li>
                            <li>
                            <label className="d-flex label-text" for="item3">
                            <img height="100%" width="100%" class="w-4 h-4 object-contain mr-2" src={ca} alt="//" />       
                                <span>Canada</span>
                            </label>
                            </li>

                        </ul>
                        </details>
                        </div>
                        
                        <form class="relative mt-lg-5 mt-md-5 mt-3  w-full sm:w-auto" action="#">
                            
                            <label class="font-mulish sm:text-xs text-[8px] tracking-[0.005em] block mb-2" for="email">Subscribe
                                Us</label>
                           
                            <input class="h-7 sm:w-44 w-full sm:text-xs text-[8px] tracking-[0.005em] text-blue-50 placeholder-blue-50 rounded-md px-3 p-2 bg-[#666666] outline-none" placeholder="Your email" name="email1" type="email" id="email1" required="" />
                            
                            <button type="submit" class="absolute right-0 bottom-0 outline-none h-7 w-7 rounded-md bg-blue-700 text-white flex items-center justify-center" aria-label="form submit">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </form>

                        <div class="space-x-6 flex items-center justify-center mt-4 sm:w-3/5 w-full">
                            {/* insta */}
                            <a class=" text-white block p-2" aria-label="instagram" href="https://www.instagram.com/">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 " fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                                </svg>
                            </a>
                             {/* fb */}
                            <a class=" text-white block p-2" aria-label="facebook" href="https://www.facebook.com/">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                                </svg>
                            </a>
                            {/* teitter */}
                            <a class=" text-white block p-2" aria-label="Twitter" href="https://twitter.com/">
                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                {/* bottom */}
                <div>
                    <p class="sm:pt-16 pt-6 pb-6 text-[7px] sm:text-[10px] lg:text-sm font-mulish text-[#A5A5A5] text-center">© 
                       { year} Rekindle Data Global Limited. Terms &amp; Conditions / Cookie policy / Privacy policy</p>
                </div>
            </div>
            {/* feedbackfin button */}
            {/* <button data-feedbackfin-button="" class="rounded-full bg-blue-800 md:h-16 md:w-16 w-10 h-10 inline-flex items-center justify-center fixed md:right-6 md:bottom-6 right-2 bottom-4 drop-shadow-md z-50 mt-24">
                <svg xmlns="http://www.w3.org/2000/svg" class=" fill-current text-white md:h-6 md:w-6 w-4 h-4" viewBox="0 0 512 512">
                    <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 49.63 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734C1.979 478.2 4.75 480 8 480c66.25 0 115.1-31.76 140.6-51.39C181.2 440.9 217.6 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32z"></path>
                </svg>
            </button> */}
            
        </footer>
        </>
    );
}
export default Footer;