import React from "react";
import headerimg from "../images/consultation-c3.png";
import bg_img from "../images/consultation-c1.svg";
import consult_settings from "../images/consultation-3setting.svg"
import idea_icon from "../images/consultation-idea_icon.svg"
import Consulting_c2 from "../images/consultation-c2.svg"
import Accordions from "../components/Accordions";
import  Carousel  from "../components/carousel/Carousel";
import Footer from "../components/Footer";

const Consulting = () => {
    return(
        <>
<main>
            {/* section 1 */}
            <section className="mt-md-0 mt-4">
              
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto text-white sm:pb-20 pb-40 flex sm:flex-row flex-col sm:items-center sm:justify-between md:gap-10 sm:gap-4 gap-28 relative lg:py-40 py-20">
                  
                    <div class=" sm:whitespace-nowrap lg:pb-32 md:pb-36 sm:pb-16">
                      
                        <h1 class="text-[28px] lg:text-4xl font-bold lg:leading-[47px!important] ">We’re not here togrow our<br/>  business. We’re here to<br/> grow yours.</h1>
                      
                        <p class="text-xs lg:text-base bg-opacity-90 mb-2">Data-driven advice on customer journey<br/> optimization, lead generation, technology<br/> integrations, and more!<br/>
                        What gets measured gets managed,<br/> therefore, measure whath matters. And <br/> that’s hard.</p>
                      
                        <button class="block outline-none md:text-sm text-xs text-white font-bold border rounded-full border-white md:px-12 px-10 sm:px-6 py-2 sm:py-3.5">Start free automation</button>
                    </div>
                  
                    <div class=" inline-block whitespace-nowrap relative">
                      
                        <button class="mx-auto flex items-center justify-center h-[78px] w-[78px] lg:h-[104px] lg:w-[104px] rounded-full bg-blue-100" aria-label="play button">
                          
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-12 h-12  lg:w-16 lg:h-16 text-[#2F4FA0]" fill="currentColor" viewBox="0 0 16 16">
                                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"></path>
                            </svg>
                        </button>
                      
                        <p class=" font-bold text-xs md:text-base lg:text-xl lg:mt-5 mt-4 text-center">How it works</p>
                      
                        <div>
                          
                            <svg xmlns="http://www.w3.org/2000/svg" class="lg:w-[247px] md:w-[144px] sm:w-[154px] h-auto absolute sm:right-full lg:bottom-0 md:bottom-5 sm:bottom-0 transform sm:transform-none rotate-90 w-24 right-0 mx-auto sm:mx-0 sm:left-auto -left-10 -top-20 sm:top-auto" viewBox="0 0 297 121" fill="none">
                                <path d="M2 117.5C1.17157 117.5 0.5 118.172 0.5 119C0.5 119.828 1.17157 120.5 2 120.5V117.5ZM297 9L282 0.339746V17.6603L297 9ZM2 120.5H125.5V117.5H2V120.5ZM151 95V33H148V95H151ZM173.5 10.5H283.5V7.5H173.5V10.5ZM151 33C151 20.5736 161.074 10.5 173.5 10.5V7.5C159.417 7.5 148 18.9167 148 33H151ZM125.5 120.5C139.583 120.5 151 109.083 151 95H148C148 107.426 137.926 117.5 125.5 117.5V120.5Z" fill="#89A7F2"></path>
                            </svg>
                          
                            <svg xmlns="http://www.w3.org/2000/svg" class="xl:w-[90px] lg:w-[76px] md:w-[60px] sm:w-[40px] h-auto absolute sm:left-full sm:bottom-0 top-0 my-auto sm:pb-5 transform sm:transform-none rotate-90 w-24 right-5 mx-auto sm:mx-0 left-0 -bottom-56" viewBox="0 0 69 18" fill="none">
                                <path d="M1.82812 2.64844C0.999698 2.64844 0.328125 3.32001 0.328125 4.14844C0.328125 4.97686 0.999698 5.64844 1.82812 5.64844V2.64844ZM68.492 9.05067L53.492 0.390415V17.7109L68.492 9.05067ZM1.82812 5.64844H32.709V2.64844H1.82812V5.64844ZM37.6112 10.5507H54.992V7.55067H37.6112V10.5507ZM33.6601 6.59955C33.6601 8.78169 35.429 10.5507 37.6112 10.5507V7.55067C37.0859 7.55067 36.6601 7.12484 36.6601 6.59955H33.6601ZM32.709 5.64844C33.2342 5.64844 33.6601 6.07427 33.6601 6.59955H36.6601C36.6601 4.41741 34.8911 2.64844 32.709 2.64844V5.64844Z" fill="#89A7F2"></path>
                            </svg>
                        </div>
                    </div>
                  
                    <div>
                      
                        <img height="100%" width="100%" class="h-auto lg:w-[466px] mx-auto" src={headerimg} alt="//"/>
                    </div>
                  
                    <div>
                      
                        <img height="100%" width="100%" class="absolute xl:top-[-315px] sm:top-[-310px] top-[-166px] xl:-left-11 left-0 right-0 -z-50 " src={bg_img} alt="//"/>
                    </div>
                    {/* scroll down */}
                    <div class="hidden sm:block absolute lg:bottom-30 md:bottom-20 bottom-16 left-[-60px]">
                        <div class="inline-flex items-center transform -rotate-90 w-auto">
                          
                            <div class="mr-1 xl:w-16 sm:w-10 w-6 h-[1px] bg-white"></div>
                          
                            <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
                          
                            <p class="xl:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish">Scroll down</p>
                        </div>
                    </div>
                    {/* social */}
                    <div class="hidden sm:block absolute lg:bottom-14 md:bottom-7 bottom-5 lg:right-0 right-5 sm:space-y-7 space-y-3 xl:space-y-3">
                        {/* insta */}
                        <a class=" text-white block p-2 icon-hov" aria-label="instagram" href="https://www.instagram.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                            </svg>
                        </a>
                        {/* fb */}
                        <a class=" text-white block p-2 icon-hov" aria-label="facebook" href="https://www.facebook.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                            </svg>
                        </a>
                        {/* twiter */}
                        <a class=" text-white block p-2 icon-hov" aria-label="Twitter" href="https://twitter.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                            </svg>
                        </a>
                    </div>
                </div>
            </section>
            {/* section 002 */}
            <section class=" relative">
              
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto text-white py-16 mb-20 sm:mb-7">
                  
                    <div class=" grid sm:grid-cols-2 grid-cols-1 gap-10 sm:gap-0 items-center">
                      
                        <div class=" relative">
                          
                            <div class=" flex items-center justify-center">
                              
                                <div class="text-center flex flex-col items-center sm:mr-10 mr-12">
                                  
                                    <p class=" text-xs sm:text-sm lg:text-xl font-bold mb-5">Business<br/> Pocess Audit</p>
                                  
                                    <div>
                                        <img height="100%" width="100%" class="h-auto lg:w-auto w-3/4 mx-auto my-auto" src={consult_settings} alt="//"/>
                                    </div>
                                  
                                    <p class=" text-xs sm:text-sm lg:text-xl font-bold my-20 xl:pb-10">Ideas &amp; Prioritisation</p>
                                  
                                    <div>
                                      
                                        <img height="100%" width="100%" class="h-auto lg:w-auto w-3/4 mx-auto" src={idea_icon} alt="//" />
                                    </div>
                                  
                                    <p class=" text-xs sm:text-sm lg:text-xl font-bold mt-5">Solutions &amp;<br/> More</p>
                                </div>
                              
                                <div class=" relative">
                                  
                                    <img height="100%" width="100%" class="h-auto lg:w-auto w-3/4 mx-auto -mt-8" src={consult_settings} alt="//" />
                                  
                                    <svg xmlns="http://www.w3.org/2000/svg" class="lg:w-auto w-36 absolute top-[-120px] lg:right-[54px] right-[48px] -z-10" width="155" height="91" viewBox="0 0 155 91" fill="none">
                                        <path d="M2 0.5C1.17157 0.5 0.5 1.17157 0.5 2C0.5 2.82843 1.17157 3.5 2 3.5V0.5ZM146 91L154.66 76H137.34L146 91ZM2 3.5H122V0.5H2V3.5ZM144.5 26V77.5H147.5V26H144.5ZM122 3.5C134.426 3.5 144.5 13.5736 144.5 26H147.5C147.5 11.9167 136.083 0.5 122 0.5V3.5Z" fill="#89A7F2"></path>
                                    </svg>
                                  
                                    <svg xmlns="http://www.w3.org/2000/svg" class="lg:w-auto w-44 absolute right-[60px] lg:bottom-[-52px] bottom-[-54px] -z-10" width="203" height="66" viewBox="0 0 203 66" fill="none">
                                        <path d="M202.5 2C202.5 1.17157 201.828 0.5 201 0.5C200.172 0.5 199.5 1.17157 199.5 2H202.5ZM9.5 66L18.1603 51H0.839746L9.5 66ZM199.5 2V14.7076H202.5V2H199.5ZM177 37.2076H33.5V40.2076H177V37.2076ZM33.5 37.2076C19.4167 37.2076 8 48.6244 8 62.7076H11C11 50.2812 21.0736 40.2076 33.5 40.2076V37.2076ZM199.5 14.7076C199.5 27.134 189.426 37.2076 177 37.2076V40.2076C191.083 40.2076 202.5 28.7909 202.5 14.7076H199.5Z" fill="#89A7F2"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                       
                      
                        <div class="lg:space-y-5 space-y-3 relative">
                          
                            <div class=" flex items-start text-blue-50">
                              
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 mr-3" viewBox="0 0 50 60" fill="none">
                                        <path d="M1 15.2422L25 29.0232V57.9647L1 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M25 29.023L1 15.242L25 1.45996L49 15.242L25 29.023Z" fill="#F5F8FF"></path>
                                        <path d="M49 15.2422L25 29.0232V57.9647L49 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M1 15.242L25 29.0231M1 15.242V44.1824L25 57.9645M1 15.242L25 57.9645M1 15.242L25 1.45996L49 15.242M25 29.0231V57.9645M25 29.0231L49 15.242M25 57.9645L49 44.1824V15.242" stroke="#101010" stroke-width="2"></path>
                                        <path d="M1 15.5861L25 29.2561V57.9644L1 44.2944V15.5861Z" fill="#101010"></path>
                                    </svg>
                                </span>
                              
                                <div class=" lg:mt-4 mt-1">
                                  
                                    <h1 class=" font-bold lg:text-2xl sm:text-lg text-sm mb-2">More Time</h1>
                                  
                                   <p class="sm:text-xs text-[9px] lg:text-base opacity-90">You want to be a business owner, not a business operator. We use systems, robots, automation and thought-provoking
                                questions to make you waste less time.</p>
                                </div>
                            </div>
                          
                            <div class=" flex items-start text-blue-50">
                              
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 mr-3" viewBox="0 0 50 60" fill="none">
                                        <path d="M1 15.2422L25 29.0232V57.9647L1 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M25 29.023L1 15.242L25 1.45996L49 15.242L25 29.023Z" fill="#F5F8FF"></path>
                                        <path d="M49 15.2422L25 29.0232V57.9647L49 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M1 15.242L25 29.0231M1 15.242V44.1824L25 57.9645M1 15.242L25 57.9645M1 15.242L25 1.45996L49 15.242M25 29.0231V57.9645M25 29.0231L49 15.242M25 57.9645L49 44.1824V15.242" stroke="#101010" stroke-width="2"></path>
                                        <path d="M1 15.5861L25 29.2561V57.9644L1 44.2944V15.5861Z" fill="#101010"></path>
                                    </svg>
                                </span>
                              
                                <div class=" lg:mt-4 mt-1">
                                  
                                    <h1 class=" font-bold lg:text-2xl sm:text-lg text-sm mb-2">More Results</h1>
                                  
                                   <p class="sm:text-xs text-[9px] lg:text-base opacity-90">We get results by auditing your business. You may have heard of UI, UX Web Design, SEO, and Social Media Marketing...
                                well, That's not us.<br/>
                                The truth is we don't really care who you choose for those services. We're proud to show you what actually works and
                                why.</p>
                                </div>
                            </div>
                          
                            <div class=" flex items-start text-blue-50">
                              
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 mr-3" viewBox="0 0 50 60" fill="none">
                                        <path d="M1 15.2422L25 29.0232V57.9647L1 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M25 29.023L1 15.242L25 1.45996L49 15.242L25 29.023Z" fill="#F5F8FF"></path>
                                        <path d="M49 15.2422L25 29.0232V57.9647L49 44.1826V15.2422Z" fill="#F5F8FF"></path>
                                        <path d="M1 15.242L25 29.0231M1 15.242V44.1824L25 57.9645M1 15.242L25 57.9645M1 15.242L25 1.45996L49 15.242M25 29.0231V57.9645M25 29.0231L49 15.242M25 57.9645L49 44.1824V15.242" stroke="#101010" stroke-width="2"></path>
                                        <path d="M1 15.5861L25 29.2561V57.9644L1 44.2944V15.5861Z" fill="#101010"></path>
                                    </svg>
                                </span>
                              
                                <div class=" lg:mt-4 mt-1">
                                  
                                    <h1 class=" font-bold lg:text-2xl sm:text-lg text-sm mb-2">More Profits</h1>
                                  
                                   <p class="sm:text-xs text-[9px] lg:text-base opacity-90">By having a clearer picture of your businesses’ data, we can identify problems faster, cut costs and ultimately
                                inscrease profit margins.</p>
                                </div>
                            </div>
                          
                            <div class="sm:h-[17rem] h-52  lg:h-[25rem] xl:h-[22rem] w-[1px] bg-black absolute top-0 left-7 -z-10"></div>
                        </div>
                      
                        <div class=" lg:rounded-br-[170px] lg:rounded-tl-[170px] md:rounded-br-[120px] md:rounded-tl-[120px] rounded-br-[70px] rounded-tl-[70px] bg-[#1B47B8] absolute left-0 right-0 top-0 bottom-0 -z-20">
                        </div>
                    </div>
                </div>
            </section>
            {/* section 3 */}
            <section>
              
                <div class="max-w-[1500px] mx-auto text-white lg:py-16 md:py-16 py-0 relative xl:my-20 lg:my-20 md:my-20 my-0 sm:py-0 sm-mb-">
                    {/* slider */}
                    <Carousel/>
                
                    
                </div>
            </section>
            {/* section 4 */}
            <section>
              
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto text-white relative z-30">
                  
                    <div>
                      
                        <img height="20%" width="20%" class="w-full h-auto" src={Consulting_c2} alt="//" />
                    </div>
                </div>
            </section>
            {/* section 5 */}


            <section className="pt-5">
            <h1 class="text-2xl md:text-[28px] xl:text-4xl font-bold text-center">FAQ</h1>                
           <div className=" pt-lg-5 pt-md-5 pt-0 xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto text-white lg:py-16 py-10">
           <Accordions></Accordions>
           </div>
            </section>
           
        </main>
        <Footer/>
        </>
    );
}
export default Consulting;