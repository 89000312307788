import React, {useState, useEffect} from "react";
import logo from "../images/logo.png"
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [navActive, setInActive] = useState("false");
    const [navbar, setNavbar] = useState(false);

    const handleToggle = () => {
      setInActive(!navActive);
    };

    const changeBackground = () => {
        if (window.scrollY >= 50) {
          setNavbar(true);
        } else {
          setNavbar(false);
        }
      };
    
      useEffect(() => {
        window.addEventListener('scroll', changeBackground, true);
        return () => window.removeEventListener('scroll', changeBackground);
      }, []);

    return(
        <>
               <nav class={`navbar navbar-expand-lg fixed-top py-2 py-lg-4 py-md-4 px-lg-5 ${navbar ? " scroll navbar-bg-scroll " : "navbar "} `} >
  <div class="container bg-transparent mx-auto px-lg-5 px-md-5 px-1">
    <a class="navbar-brand" href="/"><div className="logo">
      <img src={logo} alt="lOGO" />
    </div></a>
    <button onClick={handleToggle} className= {`navbar-toggler hamburger hamburger--collapse ${navActive ? " " : "is-active"}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
    <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
      <ul class="navbar-nav App-nav bg-transparent">
       
        <li class="nav-item mx-lg-3">
        <Link className='nav-link hover:underline' to="/leadgeneration">Lead Generation</Link>
        </li>
        <li class="nav-item mx-lg-3">
        <Link className='nav-link hover:underline' to="/audit">Website Audits</Link>
        </li>
        <li class="nav-item mx-lg-3">
        <Link className='nav-link hover:underline' to="/consulting">Consulting</Link>
        </li>
        <li className='nav-item mx-lg-3'>
        <div class="flex">
  <div>
    <div class="dropdown relative">
      <a href='/'
        class="dropdown-toggle bg-transparent inline-block py-2 sm:px-0 text-white font-medium text-xs leading-tight rounded focus:outline-none focus:ring-0 active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap"
        type="button"
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        // aria-expanded="false"
      >
        Explore

        <svg xmlns="http://www.w3.org/2000/svg"
          class=" transform duration-500 sm:h-6 sm:w-6 w-3 h-3 ml-1" viewBox="0 0 20 20"
          fill="currentColor">
          <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd" />
      </svg>
      </a>
      <ul
        class="dropdown-menu min-w-max absolute hidden list-inline shadow-md bg-black text-base left-[93px] z-50 float-left py-2 text-left rounded-lg shadow-lg mt-1 hidden m-0 bg-clip-padding border-none bg-gray-800 shadow-gray-200"
        aria-labelledby="dropdownMenuButton2">
        <div className='d-flex justify-content-between'>
        <span
          class="text-white font-semibold text-sm py-2 px-4 block w-full whitespace-nowrap bg-transparent">
          Services
        </span>
        <span
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white">
          See more</span>
        </div>
        <li>
          <a
            class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700 active:bg-blue-600"
            href="/">
            Action</a>
        </li>
        <li>
          <a
            class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700"
            href="/">
            Another action</a>
        </li>
        <li>
          <a
            class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700"
            href="/">
            Something else here</a>
        </li>
        <li>
          <a
            class="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-300 hover:bg-gray-700 hover:text-white focus:text-white focus:bg-gray-700"
            href="/">
            Separated link</a>
        </li>
      </ul>
    </div>
  </div>
</div>
        </li>
        <li class="nav-item btn-login mx-lg-3 d-mone d-lg-block d-md-block">
        <button className='btn btn-transparent '>Login</button>
        </li>
      </ul>
    </div>
  </div>
</nav>
        </>
    );
}
export default Navbar;