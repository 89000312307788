import React from "react";
import './carousel.css';
import { Swiper, SwiperSlide } from "swiper/react";
import boy_profile from "../../images/consultation-c2.png";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";


const Carousel =() =>{
    return(
        <>
        <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={1.5}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        coverflowEffect={{
          rotate: 50,
          stretch: 100,
          depth: 100,
          modifier: 0.5,
          slideShadows: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="mySwiper carousel" 
      >
        <SwiperSlide>
        <div class="swiper-slide mx-auto bg-blue-800 rounded-[20px] md:p-6 p-4 z-30 swiper-slide-visible swiper-slide-active" role="group" aria-label="2 / 3" style={{width: '1107.69px;', transitionDuration: '0ms;', transform: 'translate3d(0px, 0px, -0.0208333px) rotateX(0deg) rotateY(0deg) scale(1);', zIndex: '1;'}} data-swiper-slide-index="1">
                                
          <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet " tabindex="0" role="button" aria-label="Go to slide 2" aria-current="true"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
          
          <div class="md:p-11 sm:p-5 py-4 text-white">
              
              <div>
                  
                  <h1 class=" font-bold lg:text-2xl md:text-lg text-base md:mb-6 mb-3">ML-Based Anti-Money Laundering Tool
                      for Digital Assets Firm</h1>
                  
                  <p class="lg:text-lg md:text-sm text-xs opacity-90">“Their ML skills were top-notch.”</p>
              </div>
              
              <div class=" flex items-center md:my-4 my-3">
                  
                  <p class="md:text-3xl text-lg mr-3.5">5.0</p>
                  
                  <div class=" flex items-center space-x-2 text-[#F97316]">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                  </div>
                  
                  <div class=" flex items-center space-x-2 text-[#F97316]">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                  </div>
                  
                  <div class=" flex items-center space-x-2 text-[#F97316]">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                  </div>
                  
                  <div class=" flex items-center space-x-2 text-[#F97316]">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                  </div>
                  
                  <div class=" flex items-center space-x-2 text-[#F97316]">
                      
                      <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                  </div>
              </div>
              
              <div class=" space-y-2">
                  
                  <div class=" flex items-center">
                      
                      <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                      
                      <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                          
                          <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                          </div>
                      </div>
                  </div>
                  
                  <div class=" flex items-center">
                      
                      <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                      
                      <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                          
                          <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                          </div>
                      </div>
                  </div>
                  
                  <div class=" flex items-center">
                      
                      <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                      
                      <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                          
                          <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                          </div>
                      </div>
                  </div>
                  
                  <div class=" flex items-center">
                      
                      <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                      
                      <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                          
                          <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                          </div>
                      </div>
                  </div>
              </div>
              
              <div>
                  
                  <p class=" lg:text-lg md:text-sm text-xs md:my-9 my-4">THE PROJECT</p>
                  
                  <div class="flex items-center justify-between">
                      
                      <div class="flex items-center">
                          
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-6 md:h-6" viewBox="0 0 25 16" fill="none">
                              <path d="M19.4056 0.874126C19.0559 0.34965 18.3566 0 17.6573 0H2.27273C1.04895 0 0 1.04895 0 2.27273V13.1119C0 14.3357 1.04895 15.3846 2.27273 15.3846H17.6573C18.3566 15.3846 19.0559 15.035 19.4056 14.5105L25 7.69231L19.4056 0.874126ZM18.007 13.4615L17.6573 13.6364H2.27273C1.92308 13.6364 1.74825 13.4615 1.74825 13.1119V2.27273C1.74825 1.92308 1.92308 1.74825 2.27273 1.74825H17.6573L18.007 1.92308L22.7273 7.69231L18.007 13.4615ZM16.0839 4.54545C14.3357 4.54545 12.9371 5.94406 12.9371 7.69231C12.9371 9.44056 14.3357 10.8392 16.0839 10.8392C17.8322 10.8392 19.2308 9.44056 19.2308 7.69231C19.2308 5.94406 17.8322 4.54545 16.0839 4.54545ZM16.0839 9.09091C15.3846 9.09091 14.6853 8.56643 14.6853 7.69231C14.6853 6.81818 15.2098 6.29371 16.0839 6.29371C16.958 6.29371 17.4825 6.99301 17.4825 7.69231C17.4825 8.39161 16.958 9.09091 16.0839 9.09091Z" fill="#F5F8FF"></path>
                          </svg>
                          
                          <p class=" text-[8px] md:text-sm lg:text-base ml-2">$10,000 to $49,999</p>
                      </div>
                      
                      <div class="flex items-center">
                          
                          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 20 23" fill="none">
                              <path d="M17.619 2.22222H16.8254V0H15.2381V2.22222H4.60317V0H3.01587V2.22222H0V22.2222H20V2.22222H17.619ZM18.4127 20.6349H1.5873V8.4127H18.4127V20.6349ZM8.4127 15.3968H3.80952V10.7937H8.4127V15.3968Z" fill="#F5F8FF"></path>
                          </svg>
                          
                          <p class=" text-[8px] md:text-sm lg:text-base ml-2">Mar. 2021 - Ongoing</p>
                      </div>
                  </div>
                  
                  <div class=" md:p-4 p-2 rounded-xl bg-white mt-3">
                      
                      <p class="text-black lg:text-xl md:text-base text-xs">Project summary</p>
                      
                      <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">Exometrics created a machine learning
                          (ML) algorithm for a digital asset mining company’s anti-money laundering tool.
                          Their work involved creating a proof of concept (POC) for the firm to use in their service.</p>
                  </div>
              </div>
              
              <div class=" flex items-center space-x-2 md:my-8 my-3">
                  
                  <img height="100%" width="100%" class="lg:h-20 lg:w-20 w-14 h-14 rounded-full profile" src={boy_profile} alt="//"/>
                  
                  <div>
                      
                      <p class=" text-[8px] md:text-[10px] lg:text-sm">Ian Gilmour</p>
                      
                      <p class=" text-xs md:text-sm lg:text-lg font-medium">Former CEO, Parabolic Digital</p>
                      
                      <div class=" flex items-center">
                          
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                              <path d="M13.7 5L12.9 5.2L13.2 7C13.2 10.4 10.4 13.1 7 13.1C3.6 13.1 0.8 10.4 0.8 7C0.787367 5.89849 1.07325 4.81411 1.62731 3.86199C2.18136 2.90988 2.9829 2.12557 3.94682 1.59234C4.91075 1.0591 6.00108 0.796842 7.10206 0.833405C8.20304 0.869968 9.27357 1.20399 10.2 1.8L10.6 1C9.5 0.3 8.3 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 10.9 3.1 14 7 14C8.08954 13.9987 9.16375 13.7431 10.1371 13.2536C11.1105 12.764 11.9561 12.054 12.6067 11.18C13.2573 10.3061 13.695 9.29228 13.8848 8.21939C14.0746 7.14651 14.0113 6.04413 13.7 5ZM4.9 5.4L4.3 6L7.1 8.8L13.8 2.1L13.2 1.5L7.1 7.6L4.9 5.4Z" fill="#F5F8FF"></path>
                          </svg>
                          
                          <p class=" text-[7px] md:text-[9px] lg:text-xs font-medium ml-2">Verified</p>
                      </div>
                  </div>
              </div>
              
              <div>
                  
                  <p class="lg:text-xl md:text-base text-xs ml-2">Feedback summaryy</p>
                  
                  <div class=" md:p-4 p-2 rounded-xl bg-white">
                      
                      <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">The engagement was successful. Thanks
                          to Exometrics’ work, the company gained higher B2B engagements and sales. The team
                          delivered the POC on time and within budget, and they leveraged their data science expertise to
                          work independently.
                          Overall, they met all of the client’s expectations.</p>
                  </div>
              </div>
          </div>
      </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="swiper-slide mx-auto bg-blue-800 rounded-[20px] md:p-6 p-4 z-30 swiper-slide-visible swiper-slide-next" role="group" aria-label="3 / 3" style={{width: '1107.69px;', transitionDuration: '0ms;', transform: 'translate3d(0px, 0px, -0.0208333px) rotateX(0deg) rotateY(0deg) scale(1);', zIndex: '1;'}} data-swiper-slide-index="2">
                                
                                <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2" aria-current="true"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
                                
                                <div class="md:p-11 sm:p-5 py-4 text-white">
                                    
                                    <div>
                                        
                                        <h1 class=" font-bold lg:text-2xl md:text-lg text-base md:mb-6 mb-3">ML-Based Anti-Money Laundering Tool
                                            for Digital Assets Firm</h1>
                                        
                                        <p class="lg:text-lg md:text-sm text-xs opacity-90">“Their ML skills were top-notch.”</p>
                                    </div>
                                    
                                    <div class=" flex items-center md:my-4 my-3">
                                        
                                        <p class="md:text-3xl text-lg mr-3.5">5.0</p>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    <div class=" space-y-2">
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        
                                        <p class=" lg:text-lg md:text-sm text-xs md:my-9 my-4">THE PROJECT</p>
                                        
                                        <div class="flex items-center justify-between">
                                            
                                            <div class="flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-6 md:h-6" viewBox="0 0 25 16" fill="none">
                                                    <path d="M19.4056 0.874126C19.0559 0.34965 18.3566 0 17.6573 0H2.27273C1.04895 0 0 1.04895 0 2.27273V13.1119C0 14.3357 1.04895 15.3846 2.27273 15.3846H17.6573C18.3566 15.3846 19.0559 15.035 19.4056 14.5105L25 7.69231L19.4056 0.874126ZM18.007 13.4615L17.6573 13.6364H2.27273C1.92308 13.6364 1.74825 13.4615 1.74825 13.1119V2.27273C1.74825 1.92308 1.92308 1.74825 2.27273 1.74825H17.6573L18.007 1.92308L22.7273 7.69231L18.007 13.4615ZM16.0839 4.54545C14.3357 4.54545 12.9371 5.94406 12.9371 7.69231C12.9371 9.44056 14.3357 10.8392 16.0839 10.8392C17.8322 10.8392 19.2308 9.44056 19.2308 7.69231C19.2308 5.94406 17.8322 4.54545 16.0839 4.54545ZM16.0839 9.09091C15.3846 9.09091 14.6853 8.56643 14.6853 7.69231C14.6853 6.81818 15.2098 6.29371 16.0839 6.29371C16.958 6.29371 17.4825 6.99301 17.4825 7.69231C17.4825 8.39161 16.958 9.09091 16.0839 9.09091Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[8px] md:text-sm lg:text-base ml-2">$10,000 to $49,999</p>
                                            </div>
                                            
                                            <div class="flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 20 23" fill="none">
                                                    <path d="M17.619 2.22222H16.8254V0H15.2381V2.22222H4.60317V0H3.01587V2.22222H0V22.2222H20V2.22222H17.619ZM18.4127 20.6349H1.5873V8.4127H18.4127V20.6349ZM8.4127 15.3968H3.80952V10.7937H8.4127V15.3968Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[8px] md:text-sm lg:text-base ml-2">Mar. 2021 - Ongoing</p>
                                            </div>
                                        </div>
                                        
                                        <div class=" md:p-4 p-2 rounded-xl bg-white mt-3">
                                            
                                            <p class="text-black lg:text-xl md:text-base text-xs">Project summary</p>
                                            
                                            <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">Exometrics created a machine learning
                                                (ML) algorithm for a digital asset mining company’s anti-money laundering tool.
                                                Their work involved creating a proof of concept (POC) for the firm to use in their service.</p>
                                        </div>
                                    </div>
                                    
                                    <div class=" flex items-center space-x-2 md:my-8 my-3">
                                        
                                        <img height="100%" width="100%" class="lg:h-20 lg:w-20 w-14 h-14 rounded-full profile" src={boy_profile} alt="//" />
                                        
                                        <div>
                                            
                                            <p class=" text-[8px] md:text-[10px] lg:text-sm">Ian Gilmour</p>
                                            
                                            <p class=" text-xs md:text-sm lg:text-lg font-medium">Former CEO, Parabolic Digital</p>
                                            
                                            <div class=" flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M13.7 5L12.9 5.2L13.2 7C13.2 10.4 10.4 13.1 7 13.1C3.6 13.1 0.8 10.4 0.8 7C0.787367 5.89849 1.07325 4.81411 1.62731 3.86199C2.18136 2.90988 2.9829 2.12557 3.94682 1.59234C4.91075 1.0591 6.00108 0.796842 7.10206 0.833405C8.20304 0.869968 9.27357 1.20399 10.2 1.8L10.6 1C9.5 0.3 8.3 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 10.9 3.1 14 7 14C8.08954 13.9987 9.16375 13.7431 10.1371 13.2536C11.1105 12.764 11.9561 12.054 12.6067 11.18C13.2573 10.3061 13.695 9.29228 13.8848 8.21939C14.0746 7.14651 14.0113 6.04413 13.7 5ZM4.9 5.4L4.3 6L7.1 8.8L13.8 2.1L13.2 1.5L7.1 7.6L4.9 5.4Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[7px] md:text-[9px] lg:text-xs font-medium ml-2">Verified</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        
                                        <p class="lg:text-xl md:text-base text-xs ml-2">Feedback summaryy</p>
                                        
                                        <div class=" md:p-4 p-2 rounded-xl bg-white">
                                            
                                            <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">The engagement was successful. Thanks
                                                to Exometrics’ work, the company gained higher B2B engagements and sales. The team
                                                delivered the POC on time and within budget, and they leveraged their data science expertise to
                                                work independently.
                                                Overall, they met all of the client’s expectations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div class="swiper-slide mx-auto bg-blue-800 rounded-[20px] md:p-6 p-4 z-30 swiper-slide-visible swiper-slide-active" role="group" aria-label="3 / 3" style={{width: '1107.69px;', transitionDuration: '0ms;', transform: 'translate3d(0px, 0px, -0.0208333px) rotateX(0deg) rotateY(0deg) scale(1);', zIndex: '1;'}} data-swiper-slide-index="2">
                                
                                <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 3" aria-current="true"></span></div>
                                
                                <div class="md:p-11 sm:p-5 py-4 text-white">
                                    
                                    <div>
                                        
                                        <h1 class=" font-bold lg:text-2xl md:text-lg text-base md:mb-6 mb-3">ML-Based Anti-Money Laundering Tool
                                            for Digital Assets Firm</h1>
                                        
                                        <p class="lg:text-lg md:text-sm text-xs opacity-90">“Their ML skills were top-notch.”</p>
                                    </div>
                                    
                                    <div class=" flex items-center md:my-4 my-3">
                                        
                                        <p class="md:text-3xl text-lg mr-3.5">5.0</p>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                        
                                        <div class=" flex items-center space-x-2 text-[#F97316]">
                                            
                                            <svg xmlns="http://www.w3.org/2000/svg" class="md:h-6 md:w-6 w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    <div class=" space-y-2">
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class=" flex items-center">
                                            
                                            <div class="w-2 h-2 md:w-3 md:h-3 lg:w-4 lg:h-4 bg-white rounded-full mr-3.5 md:mr-5"></div>
                                            
                                            <div class=" sm:w-2/5 w-full h-1 sm:h-1.5 lg:h-2 bg-white shadow-3xl rounded-full relative">
                                                
                                                <div class=" w-1/3 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-[#0B2158]">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        
                                        <p class=" lg:text-lg md:text-sm text-xs md:my-9 my-4">THE PROJECT</p>
                                        
                                        <div class="flex items-center justify-between">
                                            
                                            <div class="flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 md:w-6 md:h-6" viewBox="0 0 25 16" fill="none">
                                                    <path d="M19.4056 0.874126C19.0559 0.34965 18.3566 0 17.6573 0H2.27273C1.04895 0 0 1.04895 0 2.27273V13.1119C0 14.3357 1.04895 15.3846 2.27273 15.3846H17.6573C18.3566 15.3846 19.0559 15.035 19.4056 14.5105L25 7.69231L19.4056 0.874126ZM18.007 13.4615L17.6573 13.6364H2.27273C1.92308 13.6364 1.74825 13.4615 1.74825 13.1119V2.27273C1.74825 1.92308 1.92308 1.74825 2.27273 1.74825H17.6573L18.007 1.92308L22.7273 7.69231L18.007 13.4615ZM16.0839 4.54545C14.3357 4.54545 12.9371 5.94406 12.9371 7.69231C12.9371 9.44056 14.3357 10.8392 16.0839 10.8392C17.8322 10.8392 19.2308 9.44056 19.2308 7.69231C19.2308 5.94406 17.8322 4.54545 16.0839 4.54545ZM16.0839 9.09091C15.3846 9.09091 14.6853 8.56643 14.6853 7.69231C14.6853 6.81818 15.2098 6.29371 16.0839 6.29371C16.958 6.29371 17.4825 6.99301 17.4825 7.69231C17.4825 8.39161 16.958 9.09091 16.0839 9.09091Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[8px] md:text-sm lg:text-base ml-2">$10,000 to $49,999</p>
                                            </div>
                                            
                                            <div class="flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 md:w-5 md:h-5" viewBox="0 0 20 23" fill="none">
                                                    <path d="M17.619 2.22222H16.8254V0H15.2381V2.22222H4.60317V0H3.01587V2.22222H0V22.2222H20V2.22222H17.619ZM18.4127 20.6349H1.5873V8.4127H18.4127V20.6349ZM8.4127 15.3968H3.80952V10.7937H8.4127V15.3968Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[8px] md:text-sm lg:text-base ml-2">Mar. 2021 - Ongoing</p>
                                            </div>
                                        </div>
                                        
                                        <div class=" md:p-4 p-2 rounded-xl bg-white mt-3">
                                            
                                            <p class="text-black lg:text-xl md:text-base text-xs">Project summary</p>
                                            
                                            <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">Exometrics created a machine learning
                                                (ML) algorithm for a digital asset mining company’s anti-money laundering tool.
                                                Their work involved creating a proof of concept (POC) for the firm to use in their service.</p>
                                        </div>
                                    </div>
                                    
                                    <div class=" flex items-center space-x-2 md:my-8 my-3">
                                        
                                        <img height="100%" width="100%" class="lg:h-20 lg:w-20 w-14 h-14 rounded-full profile" src={boy_profile} alt="//" />
                                        
                                        <div>
                                            
                                            <p class=" text-[8px] md:text-[10px] lg:text-sm">Ian Gilmour</p>
                                            
                                            <p class=" text-xs md:text-sm lg:text-lg font-medium">Former CEO, Parabolic Digital</p>
                                            
                                            <div class=" flex items-center">
                                                
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                    <path d="M13.7 5L12.9 5.2L13.2 7C13.2 10.4 10.4 13.1 7 13.1C3.6 13.1 0.8 10.4 0.8 7C0.787367 5.89849 1.07325 4.81411 1.62731 3.86199C2.18136 2.90988 2.9829 2.12557 3.94682 1.59234C4.91075 1.0591 6.00108 0.796842 7.10206 0.833405C8.20304 0.869968 9.27357 1.20399 10.2 1.8L10.6 1C9.5 0.3 8.3 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 10.9 3.1 14 7 14C8.08954 13.9987 9.16375 13.7431 10.1371 13.2536C11.1105 12.764 11.9561 12.054 12.6067 11.18C13.2573 10.3061 13.695 9.29228 13.8848 8.21939C14.0746 7.14651 14.0113 6.04413 13.7 5ZM4.9 5.4L4.3 6L7.1 8.8L13.8 2.1L13.2 1.5L7.1 7.6L4.9 5.4Z" fill="#F5F8FF"></path>
                                                </svg>
                                                
                                                <p class=" text-[7px] md:text-[9px] lg:text-xs font-medium ml-2">Verified</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        
                                        <p class="lg:text-xl md:text-base text-xs ml-2">Feedback summaryy</p>
                                        
                                        <div class=" md:p-4 p-2 rounded-xl bg-white">
                                            
                                            <p class="text-slate-500 lg:text-base md:text-xs text-[9px]">The engagement was successful. Thanks
                                                to Exometrics’ work, the company gained higher B2B engagements and sales. The team
                                                delivered the POC on time and within budget, and they leveraged their data science expertise to
                                                work independently.
                                                Overall, they met all of the client’s expectations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
        </SwiperSlide>
    
       
      </Swiper>
        </>
    );
}
export default Carousel;
