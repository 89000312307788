import React from "react";
import boy from "./images/slide-boy.png";
import girl from "./images/slide-girl.png";
import man from "./images/slide-man.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./homeslider.css"


// import required modules
import { Autoplay, EffectCoverflow  } from "swiper";

const SliderHome = () => {
    return(
        <>
              <div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 5,
          stretch: 0,
          depth: 2,
          modifier: 1,
          slideShadows: false,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={true}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide className="sslide">
        <div class=" slide bg-blue-800 text-white rounded-[20px] p-6 shadow-4xl mx-auto">


        <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet " tabindex="0" role="button" aria-label="Go to slide 2" aria-current="true"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
          
            <div class="swiper-pagination space-x-3">

            </div>
            
            <div class="md:p-5 lg:px-8 text-center">
                
                <div class="inline-block relative">
                    
                    <img height="100%" width="100%" class=" w-auto h-auto object-cover mx-auto" src={boy} alt="//" />
                    
                    <span
                        class="absolute bottom-[22px] right-4  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path
                                d="M0.33309 7.5709C0.333331 3.57299 3.57447 0.332227 7.57239 0.332467L18.4322 0.333122C22.4301 0.333363 25.6709 3.57451 25.6706 7.57242L25.67 18.4322C25.6697 22.4301 22.4286 25.6709 18.4307 25.6706L7.57087 25.67C3.57295 25.6697 0.332195 22.4286 0.332436 18.4307L0.33309 7.5709Z"
                                fill="#264187" />
                            <path
                                d="M11.3331 8.11488C10.2803 7.5885 9.04166 8.35403 9.04166 9.53105V16.4692C9.04166 17.6462 10.2803 18.4117 11.3331 17.8853L18.2712 14.4163C19.4382 13.8328 19.4382 12.1674 18.2712 11.5839L11.3331 8.11488Z"
                                fill="white" />
                        </svg>
                    </span>
                </div>
                
                <p class=" font-bold lg:text-xl text-lg -mt-3">Chase</p>
                <p class=" text-sm -mt-1">Director of Marketing</p>
                
                <p class="mt-8 mb-4 lg:text-sm text-xs font-mulish text-left leading-5 w-3/5 mx-auto ">We’ve purchased leads in the past with dismal results. Rekindle’s leads fed targeted new clients into our sales funnel.</p>
            </div>
            
            <div class="md:p-5 lg:px-10 pb-10">
                
                <div class=" flex items-center justify-center space-x-2 text-[#FBBC05]">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                </div>
                
                <div class="w-full h-0.5 bg-black my-5"></div>
            </div>
        </div> 
        </SwiperSlide>
        <SwiperSlide className="sslide">
          
            <div class=" slide bg-blue-800 text-white rounded-[20px] p-6 shadow-4xl mx-auto">

            <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 2" aria-current="true"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span></div>
                
                <div class="md:p-5 lg:px-8 text-center">
                    
                    <div class="inline-block relative">
                        
                        <img height="100%" width="100%" class=" w-auto h-auto object-cover mx-auto" src={man} alt="//" />
                        
                        <span
                            class="absolute bottom-[22px] right-4  ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <path
                                    d="M0.33309 7.5709C0.333331 3.57299 3.57447 0.332227 7.57239 0.332467L18.4322 0.333122C22.4301 0.333363 25.6709 3.57451 25.6706 7.57242L25.67 18.4322C25.6697 22.4301 22.4286 25.6709 18.4307 25.6706L7.57087 25.67C3.57295 25.6697 0.332195 22.4286 0.332436 18.4307L0.33309 7.5709Z"
                                    fill="#264187" />
                                <path
                                    d="M11.3331 8.11488C10.2803 7.5885 9.04166 8.35403 9.04166 9.53105V16.4692C9.04166 17.6462 10.2803 18.4117 11.3331 17.8853L18.2712 14.4163C19.4382 13.8328 19.4382 12.1674 18.2712 11.5839L11.3331 8.11488Z"
                                    fill="white" />
                            </svg>
                        </span>
                    </div>
                    
                    <p class=" font-bold lg:text-xl text-lg -mt-3">Melody</p>
                    <p class=" text-s -mt-1">COO</p>
                    
                    <p class="mt-8 mb-4 lg:text-sm text-xs font-mulish text-left leading-5 w-3/5 mx-auto  ">Our advanced data integrations deliver the perfect balance of data science and behavioural science for profitable
                    connections.</p>
                </div>
                
                <div class="md:p-5 lg:px-10 pb-10">
                    
                    <div class=" flex items-center justify-center space-x-2 text-[#FBBC05]">
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                                fill="currentColor" />
                        </svg>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                                fill="currentColor" />
                        </svg>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                                fill="currentColor" />
                        </svg>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                                fill="currentColor" />
                        </svg>
                        
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                                fill="currentColor" />
                        </svg>
                    </div>
                    
                    <div class="w-full h-0.5 bg-black my-5"></div>
                </div>
            </div>
        
        </SwiperSlide>
        <SwiperSlide className="sslide">
          <div class=" slide bg-blue-800 text-white rounded-[20px] p-6 shadow-4xl mx-auto">


          <div class="swiper-pagination2 space-x-3 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
          <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span>
          <span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span>
          <span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 3" aria-current="true"></span>
          </div>


            <div class="md:p-5 lg:px-8 text-center">
                
                <div class="inline-block relative">
                    
                    <img height="100%" width="100%" class=" w-auto h-auto object-cover mx-auto" src={girl} alt="//" />
                    
                    <span
                        class="absolute bottom-[22px] right-4  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path
                                d="M0.33309 7.5709C0.333331 3.57299 3.57447 0.332227 7.57239 0.332467L18.4322 0.333122C22.4301 0.333363 25.6709 3.57451 25.6706 7.57242L25.67 18.4322C25.6697 22.4301 22.4286 25.6709 18.4307 25.6706L7.57087 25.67C3.57295 25.6697 0.332195 22.4286 0.332436 18.4307L0.33309 7.5709Z"
                                fill="#264187" />
                            <path
                                d="M11.3331 8.11488C10.2803 7.5885 9.04166 8.35403 9.04166 9.53105V16.4692C9.04166 17.6462 10.2803 18.4117 11.3331 17.8853L18.2712 14.4163C19.4382 13.8328 19.4382 12.1674 18.2712 11.5839L11.3331 8.11488Z"
                                fill="white" />
                        </svg>
                    </span>
                </div>
                
                <p class=" font-bold lg:text-xl text-lg -mt-3">Pam</p>
                <p class=" text-s -mt-1">CEO</p>
                
                <p class="mt-8 mb-4 lg:text-sm text-xs font-mulish text-left leading-5 w-3/5 mx-auto ">Our new sales funnels instantly increased conversions of both  new leads and existing  customers!</p>
            </div>
            
            <div class="md:p-5 lg:px-10 pb-10">
                
                <div class=" flex items-center justify-center space-x-2 text-[#FBBC05]">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M9.27102 1.82333C8.69807 0.662408 7.04263 0.662405 6.46969 1.82333L5.11691 4.56434C5.08441 4.6302 5.02158 4.67584 4.94891 4.6864L1.92402 5.12595C0.642872 5.31211 0.13131 6.88652 1.05836 7.79017L3.24719 9.92375C3.29978 9.97501 3.32378 10.0489 3.31136 10.1213L2.79465 13.1339C2.5758 14.4099 3.91508 15.3829 5.06097 14.7805L7.76652 13.3581C7.83152 13.3239 7.90918 13.3239 7.97418 13.3581L10.6797 14.7805C11.8256 15.3829 13.1649 14.4099 12.946 13.1339L12.4293 10.1213C12.4169 10.0489 12.4409 9.97501 12.4935 9.92375L14.6823 7.79017C15.6094 6.88652 15.0978 5.31211 13.8167 5.12595L10.7918 4.6864C10.7191 4.67584 10.6563 4.6302 10.6238 4.56434L9.27102 1.82333Z"
                            fill="currentColor" />
                    </svg>
                </div>
                
                <div class="w-full h-0.5 bg-black my-5"></div>
            </div>
        </div>
       
        </SwiperSlide>
      </Swiper>
    </div>
        </>
    );
}
export default SliderHome;