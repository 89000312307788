import React from "react";
import w1s from "../images/w1s.png";
import w3 from "../images/w3.svg";
import w3lg from "../images/w3lg.svg";
import w3m from "../images/w3m.svg";
import w2 from "../images/w2.png";
import w1 from "../images/w1.png";
import d_arrow_dot from "../images/d-arrow-dot.svg";
import Footer from "../components/Footer";
import Company from "../components/carousel/Company";




const Audit = () => {
    return(
        <>
<main>
            {/* section 1 */}
            <section className="mt-lg-5 mt-md-3 mt-5">
                
                <div class=" xl:max-w-[1138px] lg:max-w-[1000px] mx-auto px-10 sm:px-0 text-blue-50 lg:relative  relative sm:pt-28 pt-14 pb-16 sm:pt-38 sm:pb-56 xl:pb-48 lg:pb-28 z-10 audit-header-text">
                    
                    <div class=" mt-0 md:max-w-[770px] sm:max-w-[640px] lg:mx-0 relative">
                        
                        <h2 class="text-sm md:text-[26px] lg:text-[38px] font-semibold ">Website audit for sales</h2>
                        
                        <h1 class="text-[17px] md:text-4xl lg:text-[48px] font-semibold lg:mt-4">funnel optimization</h1>
                        
                        <p class="text-[6px] md:text-[10px] lg:text-lg lg:my-7 sm:my-5 my-3 opacity-90">Leads drop-off is a real problem. We audit every part of your<br/> customer journey / experience using data to save you time,
                        reduce,<br /> headaches and make more profits.</p>
                        
                        <button class="outline-none md:text-sm text-[7px] text-white font-bold border rounded-full border-white md:px-12 px-6 py-1.5 md:py-3.5">Contact Sales</button>
                    </div>
                    
                    <div class="bg-white md:rounded-3xl sm:rounded-2xl rounded-xl shadow-5xl absolute right-0 xl:w-[590px] md:w-[430px] sm:w-[380px] w-[42%] xl:h-[439.13px] sm:h-[345.9px] h-[145.7px] p-5 top-0 bottom-0 my-auto">
                        
                        <div class="space-x-0.5 flex items-center">
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                        </div>
                        
                        <div class=" flex w-full h-full items-end justify-end xl:pr-10 sm:pr-5">
                            
                            <img height="100%" width="100%" class="xl:max-w-[209px] sm:max-w-[164.63px] max-w-[110.45px] w-auto h-auto xl:mb-[-215px] sm:mb-[-172px] mb-[-135px]" src={w1s} alt="//" />
                        </div>
                    </div>
                    {/* scroll down */}
                    <div class=" absolute lg:bottom-0 md:bottom-10 bottom-0 lg:left-[-70px] md:left-0">
                        <div class="inline-flex items-center transform -rotate-90 w-auto">
                            
                            <div class="mr-1 xl:w-16 sm:w-10 w-6 h-[1px] bg-white"></div>
                            
                            <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
                            
                            <p class="xl:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish">Scroll down</p>
                        </div>
                    </div>
                </div>
                
                <div class="shadow-5xl xl:rounded-br-[170px] md:rounded-br-[133.908px] rounded-br-[74.5918px] bg-[#1B47B8] absolute left-0 xl:right-[48%] lg:right-[41%] md:right-[37%] sm:right-[46%] right-[28%] -z-50 top-0 xl:h-[790px] lg:h-[650px] sm:h-[680px] h-[400px]"></div>
            </section>
            {/* section 2 */}
            <section>
                
                <div class="z-30 mt-20 xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] mx-auto px-10 sm:px-0 relative">
                    
                    <div>
                        
                        <img height="100%" width="100%" class="hidden xl:block w-full h-auto object-cover xl:pr-14" src={w3} alt="//" />
                        
                        <img height="100%" width="100%" class="xl:hidden md:block hidden w-full h-auto object-cover md:pr-24" src={w3lg} alt="//" />
                        
                        <img height="100%" width="100%" class="md:hidden block w-full h-auto object-contain" src={w3m} alt="//" />
                    </div>
                    
                    <div class="whitespace-nowrap absolute xl:top-[18%] lg:top-[17%] sm:top-[14%] top-[14%] left-[65%] xl:left-[70%] sm:right-0 z-10">
                        
                        <h1 class=" text-xs sm:text-2xl md:text-3xl lg:text-4xl text-white font-semibold mb-5 sm:mb-10 lg:mb-20">Data over opinions</h1>
                        
                        <p class="text-[7px] sm:text-base lg:text-xl text-[#64748B]">We share the insights with you. The data<br/> tells the story of how to create the<br /> website experience your audience<br /> expects.</p>
                    </div>
                    {/* scroll down */}
                    <div class=" md:flex hidden absolute md:-top-20 bottom-0 my-auto -right-12">
                        <div class="inline-flex items-center transform -rotate-90 w-auto">
                            
                            <div class="mr-1 lg:w-16 sm:w-10 w-6 h-[1px] bg-blue-700"></div>
                            
                            <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
                            
                            <p class="lg:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish">Scroll down</p>
                        </div>
                    </div>
                    {/* social */}
                    <div class="sm:space-y-7 space-y-3 xl:space-y-3 md:block hidden absolute right-0 lg:-top-28 -top-10  z-20">
                        {/* insta */}
                        <a class=" text-white block p-2 icon-hov" aria-label="instagram" href="https://www.instagram.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                            </svg>
                        </a>
                        {/* fb */}
                        <a class=" text-white block p-2 icon-hov" aria-label="facebook" href="https://www.facebook.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                            </svg>
                        </a>
                        {/* twitter */}
                        <a class=" text-white block p-2 icon-hov" aria-label="Twitter" href="https://twitter.com/">
                            <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                            </svg>
                        </a>
                    </div>
                    
                    <div>
                        
                        <img height="100%" width="100%" class="h-auto xl:w-[220px] w-1/4 absolute xl:top-[-175px] sm:top-[-209px] top-[-143px] xl:left-[318px] sm:left-[31%] left-[36%] z-30" src={d_arrow_dot} alt="//" />
                    </div>
                </div>
            </section>
            {/* section 3 */}
            <section class=" bg-[#1B47B8] mb-[130px] sm:mb-[360px] md:mb-auto">
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] mx-auto px-10 sm:px-0 sm:py-20 py-16 text-white flex md:flex-row flex-col md:gap-32 gap-10 relative">
                    
                    <div class="xl:w-1/2 md:w-[41%] xl:py-10">
                        
                        <h1 class=" lg:text-4xl text-[26px] font-bold lg:leading-[46.8px] text-shadow-3xl">How many leads are you <br/> losing in your current funnel?</h1>
                        
                        <p class=" text-sm lg:text-xl lg:mt-6 mt-4 mb-11 opacity-80 ">We identify the flaws and fixes in your <br/> current sales funnel by creating an<br/> engaging and effective customer journey.</p>
                        
                        <img height="100%" width="100%" class="w-auto h-auto" src={w2} alt="//" />
                    </div>
                    
                    <div class="bg-white md:rounded-3xl sm:rounded-2xl rounded-xl shadow-5xl md:absolute top-0 bottom-0 right-0 xl:w-[618px] sm:w-[447px] xl:h-[529px] sm:h-[382px] w-full h-[200px] my-auto mx-auto p-5 mb-[-215px] sm:mb-[-390px] md:mb-auto">
                        
                        <div class="space-x-0.5 flex items-center">
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                            
                            <div class=" md:h-3 md:w-3 w-2 h-2 rounded-full bg-black"></div>
                        </div>
                        
                        <div class="md:p-10 p-4 flex flex-col h-full items-center justify-between">
                            
                            <div class=" w-full h-1 sm:h-1.5 lg:h-2 bg-[#262626] shadow-3xl rounded-full relative">
                                
                                <div class=" w-1/4 h-1 sm:h-1.5 lg:h-2 rounded-l-full bg-gradient-to-br from-[#6505B4] via-[#4303FA] to-[#0047FF]">
                                </div>
                            </div>
                            
                            <button class="mx-auto outline-none sm:text-sm text-[7px] text-white font-semibold rounded-full sm:px-12 px-8 py-1.5 sm:py-2.5 tracking-[0.0025em] bg-[#1B47B8] font-mulish">Calculate
                                your cost</button>
                        </div>
                    </div>
                </div>
            </section>
            {/* section 4 */}
            <section>
                
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] mx-auto px-10 sm:px-0 lg:py-20 md:py-20 sm:py-0 grid xl:grid-cols-5 sm:grid-cols-3 grid-cols-2 items-center md:gap-10 gap-2.5 relative z-30">
                    
                    <div class="xl:col-span-2 sm:col-span-1">
                        
                        <img height="100%" width="100%" class="w-auto h-auto mx-auto" src={w1} alt="//" />
                    </div>
                    
                    <div class="xl:col-span-3 sm:col-span-2 text-white">
                        
                        <h1 class="xl:text-4xl md:text-3xl text-base">Ready to leverage your data at scale?</h1>
                         
                        <p class="xl:text-xl md:text-lg text-[9px] md:mt-5 mt-1 font-light md:pb-9 pb-5">Keep your preferred marketing and design team and trust Rekindle Data to leverage your data for sales funnel <br/>
                        optimization.</p>
                        
                        <ul class=" md:space-y-3.5 space-y-1">
                            
                            <li class=" flex items-center space-x-3.5 xl:text-base md:text-sm text-[7px]">
                                
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 md:h-5 xl:h-6 w-3 md:w-5 xl:w-6" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8301 23.8995V12.8428L24.0472 7.31445V18.3711L12.8301 23.8995Z" fill="#1E3A8A" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8303 23.8995L1.61328 18.3711V7.31445L12.8303 12.8428V23.8995Z" fill="#1D4ED8" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.61328 7.31453L12.8303 1.78613L24.0474 7.31453L12.8303 12.8429L1.61328 7.31453Z" fill="#3B82F6" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                </svg>
                                
                                <p>Audit before purchasing leads</p>
                            </li>
                            
                            <li class=" flex items-center space-x-3.5 xl:text-base md:text-sm text-[7px]">
                                
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-3 md:h-5 xl:h-6 w-3 md:w-5 xl:w-6" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8301 23.8995V12.8428L24.0472 7.31445V18.3711L12.8301 23.8995Z" fill="#1E3A8A" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8303 23.8995L1.61328 18.3711V7.31445L12.8303 12.8428V23.8995Z" fill="#1D4ED8" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.61328 7.31453L12.8303 1.78613L24.0474 7.31453L12.8303 12.8429L1.61328 7.31453Z" fill="#3B82F6" stroke="#1D4ED8" stroke-width="0.134604" stroke-linejoin="round"></path>
                                </svg>
                                
                                <p>Ongoing audits for optimized results</p>
                            </li>
                        </ul>
                        
                        <button class="md:mt-14 mt-5 outline-none sm:text-sm text-[7px] text-white font-bold border rounded-full border-white sm:px-12 px-8 py-1.5 sm:py-2.5">Start free website audit</button>
                    </div>
                </div>
            </section>
            {/* section 5 */}
            <section class=" bg-[#1B47B8] mt-2 mb-2">
              
              <div class="xl:max-w-[1138px] lg:max-w-[1000px] mx-auto relative py-10 text-white">
                  <div className="py-0 my-0">
                    <Company/>
                  </div>
              </div>
          </section>
        </main>
        <Footer/>
        </>
    );
}
export default Audit;