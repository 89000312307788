import React from "react";
import { Accordion } from "react-bootstrap";


const Accordions = () => {
    return(
         <>
        <div x-data="{selected:'1'}" class="grid md:grid-cols-2 grid-cols-1 gap-6 pt-10">
            <div class="flex flex-col gap-6">
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>What is data consulting?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>Does my small business really need blockchain technology?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 mb-4 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>How does blockchain work?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 mb-4 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>Didn’t my website designer do this all for me?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 mb-4 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>What is a website audit?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 mb-4 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

        
            </div>
            <div class="flex flex-col gap-6">
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>How do your services differ from digital marketing?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>Are you an outside sales firm?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>Why purchase leads?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>What are real-time leads?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title text-xs"><span>How are your leads better than leads I’ve purchased in the past?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="1">
                <Accordion.Header className="accordian-title"><span>What is customer journey optimization?</span></Accordion.Header>
                <Accordion.Body>
                <p class="text-[10px] md:text-xs lg:text-sm  lg:pb-5 opacity-90">Rekindle Data helps you move beyond traditional touchpoint insights. We empower you to sincerely understand your
                            customers by connecting data in meaningful ways.</p>
                </Accordion.Body>
            </Accordion.Item>
            </Accordion>

            
            </div>
        </div>
         </>
    );

}
export default Accordions;