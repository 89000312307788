import React from "react";
import Footer from "../components/Footer";
import imgb1 from "../images/b1.svg";
import imgb2 from "../images/b11.svg";
import contact_img from "../images/e1.png";

import SliderHome from "./sliderhome/SliderHome";
import Company from "../components/carousel/Company";

const Home = () => {

     
    return(
        <>

             <section class=" relative lg:min-h-[650px] min-h-[475px] pt-md-5 pt-5">
               <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto relative">
                    <div class="absolute xl:top-[132px] lg:top-[110px] sm:top-[55px] top-[104px] left-[93px] sm:left-auto">
                    
                        <div class="flex items-center  lg:mb-[90px] sm:mb-[70px] mb-[33px] text-main">
                                <span
                                class="xl:mr-12 sm:mr-9 mr-5 rounded-full border border-blue-300 xl:h-9 xl:w-9 sm:w-7 sm:h-7 w-4 h-4 flex items-center justify-center ">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2 " fill="none" viewBox="0 0 24 24" stroke="currentColor"
                                    stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </span>
                                <h1 class="xl:text-6xl sm:text-[46.29px] text-xl tracking-[0.14em] text-[#6C6C6C]  font-bold uppercase lead lead-sm">Lead Generation</h1>
                        </div>

                        <div class=" flex items-center text-[#6C6C6C] lg:mb-[84px] sm:mb-[64px] mb-[33px] sm:ml-10 ml-4">
                            <span
                            class="xl:mr-8 sm:mr-6 mr-3 rounded-full border border-blue-300 opacity-80 xl:h-6 xl:w-6 sm:w-4 sm:h-4 w-2 h-2 flex items-center justify-center "></span>
                            <h1 class="xl:text-[44px] sm:text-[33.95px] text-base font-bold hover:text-white focus:text-white lead-genrate">Website Audits</h1>
                        </div>
                       
                        <div class=" sub-panga flex items-center text-[#3B3B3B]">
                            <span
                            class="xl:mr-[60px] sm:mr-[46px] mr-[22px] rounded-full border border-blue-300  opacity-20 xl:h-[18px] xl:w-[18px] sm:w-[14px] sm:h-[14px] w-[7px] h-[7px] flex items-center justify-center "></span>
                            <h1 class="xl:text-4xl sm:text-[28px] text-sm font-bold hover:text-white focus:text-white lead-genrate">Consulting</h1>
                               
                        </div>
                        
                    </div>
                    <div>
                        <img height="100%" width="100%" class="sm:block hidden -z-10 absolute sm:top-[-55px] md:top-[-96px] xl:left-[-680px] lg:left-[-595px] md:left-[-450px] sm:left-[-363px] w-auto h-auto" src={imgb1} alt="//" />
                        <img height="100%" width="100%" class="block sm:hidden -z-10 absolute -top-20 left-0 w-auto h-auto" src={imgb2} alt="//" />
                    </div>
                    <div class=" flex items-center flex-col absolute xl:top-52 lg:top-40 md:top-32 top-40 lg:right-0 sm:-right-8 right-5"> 
                        <div class="sm:space-y-7 space-y-3 xl:space-y-3 block">
                            <a class=" text-white block p-2 icon-hov" aria-label="instagram" href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor" 
                                    viewBox="0 0 16 16">
                                    <path
                                        d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                </svg>
                            </a>
                            <a class=" text-white block p-2 icon-hov" aria-label="facebook" href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4"  fill="currentColor" viewBox="0 0 16 16"> 
                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                </svg>
                            </a>
                            <a class=" text-white block p-2 icon-hov" aria-label="Twitter" href="https://twitter.com/" target="_blank" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" class=" lg:w-5 w-4" fill="currentColor"
                                viewBox="0 0 16 16">
                                <path
                                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                </svg>
                            </a>
                        </div>
                        <div class="block lg:mt-32 sm:mt-20 mt-10">
                            <div class="inline-flex items-center transform rotate-90 w-auto">
                                <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mr-1 scroll-sm rounded-full bg-white"></div>
                                <p class="lg:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] scroll-sm text-white font-mulish mb-0">Scroll down</p>
                                <div class="ml-1 mt-1 lg:w-16 sm:w-10 w-6 h-[1px] bg-blue-700"></div>
                            </div>
                        </div>
                    </div>
               </div>
            </section>

{/* Section 2 */}
            <section class=" bg-[#1B47B8]">
               
                <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto relative py-20 text-white">
                   
                    <p class=" lg:text-2xl text-xl font-bold text-center pb-40 pb-sm-data-gold">Data is the new gold, what's your data?</p>
                   
                    <div class=" grid sm:grid-cols-3 grid-cols-1 sm:justify-center xl:gap-32 md:gap-5 sm:gap-5 gap-20 relative z-30 whitespace-nowrap">
                        <div class=" flex sm:flex-col flex-row sm:items-center relative">
                                <div class="flex sm:items-center items-start justify-center sm:flex-col flex-row mr-8 sm:mr-0 ">
                                <div class=" flex sm:flex-col flex-row items-center justify-center">
                                    <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl border border-blue-400 shadow-3xl sm:order-1 order-2 icon-box1">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" class="lg:h-9 lg:w-9 h-7 w-7 " viewBox="0 0 24 24"
                                            stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                                        </svg>
                                    </div>
                                        <div class="z-30 lg:h-6 h-5 w-5 lg:w-6 rounded-full bg-white sm:mt-9 mr-7 sm:mr-0 sm:order-2 order-1 icon-dot"></div>
                                        <div class="sm:h-1 sm:w-full w-1 h-full bg-[#0E3087] rounded-full absolute sm:top-24 -top-16 z-20 left-2 sm:left-[-1px] top-0">
                                    </div>
                                </div>
                                </div>
                                <div class="sm:mt-10 data-text-li-sm">
                                        <p class=" lg:text-2xl text-lg font-bold">Lead Generation</p>
                                        <ul class=" list-disc ml-4 text-sm leading-7 font-mulish mt-1  mb-4">
                                                <li>Pre-qualified local & <br/> nationwide leads</li>
                                                <li>Hyper-targeted<br/> demographics</li>
                                                <li>Review leads before you <br/>commit</li>
                                </ul>
                                        <button class="border border-white outline-none rounded-full px-9 py-3 font-mulish font-semibold text-sm xl:text-lg btn-data-sm">See an Example Lead</button>
                            </div>
                        </div>
                        <div class=" flex sm:flex-col flex-row sm:items-center">
                                <div class="flex sm:items-center items-start justify-center sm:flex-col flex-row mr-8 sm:mr-0">
                                   <div class=" flex sm:flex-col flex-row items-center justify-center">
                                        <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#2F4FA0] rounded-xl shadow-3xl sm:order-1 order-2 icon-box2">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M4.56789 4.56756C4.56789 2.35104 6.36473 0.554199 8.58125 0.554199H18.7971C21.0136 0.554199 22.8104 2.35104 22.8104 4.56756V10.4052C22.8104 12.6217 21.0136 14.4185 18.7971 14.4185H18.0674C17.0599 14.4185 16.2431 13.6018 16.2431 12.5943V8.5288C16.2431 7.75158 15.6131 7.12152 14.8358 7.12152H7.12184C5.71133 7.12152 4.56789 5.97807 4.56789 4.56756ZM8.58125 2.7433C7.57374 2.7433 6.75699 3.56005 6.75699 4.56756C6.75699 4.76906 6.92034 4.93241 7.12184 4.93241H14.8358C16.8221 4.93241 18.4322 6.54257 18.4322 8.5288V12.2294H18.7971C19.8046 12.2294 20.6213 11.4127 20.6213 10.4052V4.56756C20.6213 3.56005 19.8046 2.7433 18.7971 2.7433H8.58125Z"
                                                fill="#F5F8FF" />
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0.189678 8.94598C0.189678 6.72946 1.98652 4.93262 4.20304 4.93262H14.4189C16.6354 4.93262 18.4322 6.72946 18.4322 8.94598V14.7836C18.4322 17.0001 16.6354 18.797 14.4189 18.797H10.951L7.61102 22.5755C6.43919 23.9012 4.25573 22.9499 4.42868 21.189L4.66361 18.797H4.20304C1.98652 18.797 0.189678 17.0001 0.189678 14.7836V8.94598ZM4.20304 7.12172C3.19553 7.12172 2.37878 7.93847 2.37878 8.94598V14.7836C2.37878 15.7911 3.19553 16.6078 4.20304 16.6078H5.06605C6.14403 16.6078 6.98693 17.5376 6.88157 18.6104L6.7175 20.281L9.4197 17.2239C9.76598 16.8322 10.2637 16.6078 10.7865 16.6078H14.4189C15.4264 16.6078 16.2431 15.7911 16.2431 14.7836V8.94598C16.2431 7.93847 15.4264 7.12172 14.4189 7.12172H4.20304Z"
                                                fill="#F5F8FF" />
                                        </svg>
                                        </div>
                                        <div class=" lg:h-3 h-2.5 w-2.5 lg:w-3 rounded-full bg-white sm:mt-[43px] mr-7 ml-1.5 sm:ml-0 sm:mr-0 sm:order-2 order-1 icon-dot-sm"></div>
                                   </div>
                               
                            </div>
                                <div class="sm:mt-10 data-text-li-sm">
                                        <p class=" lg:text-2xl text-lg font-bold">Website Audits</p>
                                        <ul class=" list-disc ml-4 text-sm leading-7 font-mulish mt-1 mb-12">
                                                <li>Revise & monetize your<br/>business process</li>
                                                <li>Advanced data integrations</li>
                                                <li>Map & optimize your<br/> customer’s journey</li>
                                </ul>
                                        <button class="border border-white outline-none rounded-full px-9 py-3 font-mulish font-semibold text-sm xl:text-lg btn-data-sm">How it Works</button>
                            </div>
                        </div>
                        <div class=" flex sm:flex-col flex-row sm:items-center">
                                <div class="flex sm:items-center items-start justify-center sm:flex-col flex-row mr-8 sm:mr-0">
                                <div class=" flex sm:flex-col flex-row items-center justify-center icon-data">
                                        <div class="flex items-center justify-center h-[50px] w-[50px] bg-[#1B47B8] rounded-xl shadow-3xl sm:order-1 order-2 icon-box3">
                                           <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-5 h-4 w-4 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd"
                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                clip-rule="evenodd" />
                                           </svg>
                                        </div>
                                                <div class=" lg:h-3 h-2.5 w-2.5 lg:w-3 rounded-full bg-white sm:mt-[43px] ml-1.5 sm:ml-0 mr-7 sm:mr-0 sm:order-2 order-1 icon-dot-sm3"></div>
                                </div>
                               
                            </div>
                                <div class="sm:mt-10 data-text-li-sm">
                                        <p class=" lg:text-2xl text-lg font-bold">Consulting</p>
                                        <ul class=" list-disc ml-4 text-sm leading-7 font-mulish mt-1  mb-5">
                                                <li>Move beyond traditional<br/>touchpoint insights</li>
                                                <li>Connect data in meaningful<br/> ways</li>
                                                <li>Sincerely understand your<br/>customers</li>
                                </ul>
                                        <button class="border border-white outline-none rounded-full px-9 py-3 font-mulish font-semibold text-sm xl:text-lg btn-data-sm"> See more about Pricing</button>
                            </div>
                        </div>
                        <div class="sm:h-1 sm:w-full w-1 h-full bg-white rounded-full absolute sm:top-24 -top-16 z-10 left-2 sm:left-auto top-0"></div>
                    </div>
                </div>
            </section>

          

            {/* section 3 */}

            <section>
                
                <div  class="xl:max-w-[1138px] relative md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto py-10 mb-20 mb-sm">
                    
                    <div class=" grid sm:grid-cols-2 grid-cols-1 gap-10 lg:gap-0 items-center">
                        
                        <div class=" text-white sm:text-left xl:w-3/5 w-10/12">
                            
                            <h1 class="font-bold lg:text-[44px] text-4xl leading-tight">Your business growth partner</h1>
                            
                            <p class=" lg:text-lg text-sm font-mulish leading-[30px!important] mt-3">We put data to work for you. Using AI we uncover profitable consumer insights to deliver a rapid increase in leads and
                            conversions</p>
                            
                            <button  class="mt-12 block outline-none text-sm text-white font-bold border rounded-full border-blue-800 px-12 py-3.5 mx-auto sm:mx-0">Join them</button>
                        </div>
                        
                        <div class=" grid grid-cols-2 sm:gap-9 gap-7 lg:gap-11">
                            
                            <div class="bg-white text-black rounded-lg py-8 sm:py-4 shadow-3xl text-center number-box">
                                
                                <div class="flex items-center justify-center h-[50px] w-[50px] bg-white border border-blue-800 rounded-xl shadow-3.5xl mx-auto mb-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-5 h-4 w-4 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                
                                <p class=" font-light lg:text-[40px] sm:text-[33px] text-[28px] mb-3 box-number">10,000s</p>
                                
                                <p class="text-xs sm:text-sm lg:text-base font-bold box-text">Leads day</p>
                            </div>
                            
                            <div class="bg-[#2F4FA0] border border-blue-400 text-white rounded-lg py-8 shadow-3xl text-center number-box">
                                
                                <div class="flex items-center justify-center h-[50px] w-[50px] bg-blue-800 border border-blue-800 rounded-xl shadow-3.5xl mx-auto mb-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-5 h-4 w-4 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                
                                <p class=" font-light lg:text-[40px] sm:text-[33px] text-[28px] mb-3 box-number">$1M+</p>
                                
                                <p class="text-xs sm:text-sm lg:text-base font-bold box-text ">Daily business</p>
                            </div>
                            
                            <div class="bg-[#2F4FA0] border border-blue-400 text-white rounded-lg py-8 shadow-3xl text-center number-box">
                                
                                <div class="flex items-center justify-center h-[50px] w-[50px] bg-blue-800 border border-blue-800 rounded-xl shadow-3.5xl mx-auto mb-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-5 h-4 w-4 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                
                                <p class=" font-light lg:text-[40px] sm:text-[33px] text-[28px] mb-3 box-number">1000+</p>
                                
                                <p class="text-xs sm:text-sm lg:text-base font-bold box-text">Services</p>
                            </div>
                            
                            <div class="bg-white text-black rounded-lg py-8 shadow-3xl text-center number-box">
                                
                                <div
                                    class="flex items-center justify-center h-[50px] w-[50px] bg-white border border-blue-800 rounded-xl shadow-3.5xl mx-auto mb-2.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="sm:h-5 h-4 w-4 sm:w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                
                                <p class=" font-light lg:text-[40px] sm:text-[33px] text-[28px] mb-3 box-number">500k+</p>
                                
                                <p class="text-xs sm:text-sm lg:text-base font-bold box-text">small businesses</p>
                            </div>

                        </div>
                    </div>

                    {/* scroll Down */}
                    <div class="block absolute -bottom-0 sm:-left-16 right-0">
                        <div class="inline-flex items-center transform -rotate-90 w-auto">
                            
                            <div class="mr-1 mt-1 lg:w-16 sm:w-10 w-6 h-[1px] bg-blue-700"></div>
                            
                            <div class="sm:h-1 sm:w-1 w-0.5 h-0.5 mx-2 rounded-full bg-white"></div>
                            
                            <p class="lg:text-xs sm:text-[9px] text-[4px] tracking-[0.005em] text-white font-mulish mb-0">Scroll down</p>
                        </div>
                    </div>

                </div>
               
            </section>

            {/* section 4 */}

            <section class="min-h-[811px]">
                
                <div class="xl:max-w-[1138px] lg:max-w-[1000px] mx-auto relative py-20 md:py-16 home-carousel-header">
                    
                    <p class=" lg:text-4xl text-3xl font-bold text-center pb-20 text-white px-10 sm:px-0 margin-sm-bottom">Don’t just take our word for it</p>
                    
                {/* Slider  */}
                 <SliderHome/>
                   
                </div>
            </section>

            {/* section 5 */}

            <section class=" bg-[#1B47B8]">
              
                <div class="xl:max-w-[1138px] lg:max-w-[1000px] mx-auto relative py-10 text-white">
                    <p class=" lg:text-4xl text-[28px] font-bold text-center pb-10">Companies</p>
                    <div className="py-0 my-0">
                      <Company/>
                    </div>
                </div>
            </section>

            {/* Section 6  Contact Mail*/}

            <div class="xl:max-w-[1138px] md:max-w-[770px] lg:max-w-[1000px] sm:max-w-[640px] px-10 sm:px-0 mx-auto py-24">
                    <div class=" grid sm:grid-cols-2 grid-cols-1 sm:gap-16 gap-10 justify-center items-center">
                            <div>
                                    <img height="100%" width="100%" class="w-4/5 h-auto object-cover mx-auto sm:mx-0" src={contact_img} alt="//" />
                        </div>
                            <div>
                                    <form action="#">
                                            <label class="text-base sm:text-xl text-white font-bold pb-2 block" for="email">Email</label>
                                            <input class="border-blue-700 text-white px-4 py-2 w-full h-14 rounded-full border  bg-transparent outline-none" name="email" type="email" id="email" required="" />
                                            <p class=" text-xs sm:text-sm font-mulish text-blue-50 mt-3 sm:mb-12 mb-4 block md:w-4/5">By providing your email you agree to our Privacy Policy.</p>
                                            <button type="submit" class=" outline-none lg:text-sm text-xs text-white font-bold border rounded-full  px-12 py-3.5 border-blue-800">Contact
                                    us</button>
                            </form>
                        </div>
                    </div>
                </div>

                {/* footer */}

                <Footer/>
        </>
    );
}
export default Home;