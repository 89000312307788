import React from "react";
import fb from "./images/fb.svg";
import github from "./images/github.svg";
import google from "./images/google.svg";
import insta from './images/instagram.svg';
import linkedin from "./images/linkdin.svg";
import telegram from "./images/teligram.svg";
import twitter from "./images/twiter.svg";
import vimeo from "./images/vimeo.svg"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

export default function SocialSlider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
    
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          300:{
            slidesPerView: 4,
            spaceBetween: 20,
          },
          375: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 9,
            spaceBetween: 50,
          },
        }}
       
        className="mySwiper social-slider"
      >
        <SwiperSlide><img src={fb} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={insta} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={linkedin} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={twitter} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={google} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={linkedin} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={telegram} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={vimeo} alt="//" /></SwiperSlide>
        <SwiperSlide><img src={github} alt="//" /></SwiperSlide>
      </Swiper>
    </>
  );
}
