import React from "react";
import com1 from "./images/_com1.png";
import com2 from "./images/_com2.png";
import com3 from "./images/_com3.png";
import com4 from "./images/_com4.png"
import com5 from "./images/_com5.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./company.css";

export default function Company() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          300: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
            375: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}
       
        className="mySwiper company-slider py-sm-0"
      >
        <SwiperSlide className="company-carousel-logo"><img  src={com1} alt="//" /></SwiperSlide>
        <SwiperSlide className="company-carousel-logo"><img src={com2} alt="//" /></SwiperSlide>
        <SwiperSlide className="company-carousel-logo"><img src={com3} alt="//" /></SwiperSlide>
        <SwiperSlide className="company-carousel-logo"><img src={com4} alt="//" /></SwiperSlide>
        <SwiperSlide className="company-carousel-logo"><img src={com5} alt="//" /></SwiperSlide>
      </Swiper>
    </>
  );
}
